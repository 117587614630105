import account from './account';
import application from './application';
import diary from './diary';
import product from './product';
import panel from './panel';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {Models} from '@rematch/core';

export interface RootModel extends Models<RootModel> {
  // @ts-ignore
  account: typeof account;
  // @ts-ignore
  application: typeof application;
  // @ts-ignore
  product: typeof product;
  // @ts-ignore
  panel: typeof panel;
  // @ts-ignore
  diary: typeof diary;
}

export const models: RootModel = {account, application, product, panel, diary};
