import { Config } from './types/config'

const config: Config = {
    header: {
        title: 'Admin',
        description: 'Your journal for life.'
    },
    network: {
        // baseURL: 'https://api.sot.app/',
        // baseURL: 'https://cn.api.sot.app/pure-api-proxy/',
        baseURL: 'http://139.198.118.244:3000/',
        timeout: 1000 * 15,
        keyForTokenInHeader: 'Authorization',
    },
    diary: {
        autoSaveAndRestore: true,
        pageCount: 15,
        redirectTargetAfterCreate: "list"
    }
}

export default config