import {createModel, RematchDispatch} from '@rematch/core';
import {AxiosError} from 'axios';
import Network from '../util/network';
import APIMapping from '../util/APIMapping';
import Storage, {StorageKey} from '../util/storage';
import Rule from '../util/rule';
import {RootModel} from './index';

/**
 * types
 */
export interface LoginRequestData {
  email: string;
  password: string;
}

interface RegisterRequestData extends LoginRequestData {
  name: string;
}

interface LoginStatusData {
  isLoggedIn: boolean;
}

interface ErrorData {
  code: number | string;
  description: string;
}

export interface AccountState {
  // basic information
  username: string;
  email: string;
  // status
  isLoggedIn: boolean;
  loading: boolean;
  error: boolean;
  // others
  errorCode: number;
  errorDescription: string;
}

const initialState: AccountState = {
  // basic information
  username: '',
  email: '',
  // status
  isLoggedIn: !!Storage.get(StorageKey.authToken), // todo: load local storage to read token
  loading: false,
  error: false,
  // others
  errorCode: 0,
  errorDescription: '',
};
// @ts-ignore
const account = createModel<RootModel>()({
  // initial state
  state: initialState,
  reducers: {
    updateLogInStatus(state: AccountState, payload: LoginStatusData) {
      const {isLoggedIn} = payload;
      return {...state, isLoggedIn};
    },
    updateLoadingStatus(state: AccountState, payload: boolean) {
      return {...state, loading: payload};
    },
    displayError(state: AccountState, payload: ErrorData) {
      return {
        ...state,
        error: true,
        errorCode: payload.code,
        errorDescription: payload.description,
      } as AccountState;
    },
    clearError(state: AccountState, payload: undefined) {
      return {...state, error: false, errorCode: 0, errorDescription: ''};
    },
  },
  effects: (dispatch: RematchDispatch<RootModel>) => ({
    async register(payload: RegisterRequestData) {
      dispatch.account.clearError();
      // check user input error first
      const {name, email, password} = payload;
      if (name.length === 0) {
        dispatch.account.displayError({
          code: 'empty-name',
          description: 'empty-name',
        });
        return;
      }
      if (email.length === 0) {
        dispatch.account.displayError({
          code: 'empty-account',
          description: 'empty-account',
        });
        return;
      }
      if (password.length === 0) {
        dispatch.account.displayError({
          code: 'empty-password',
          description: 'empty-password',
        });
        return;
      }
      // check format
      if (!Rule.account.name.test(name)) {
        dispatch.account.displayError({
          code: 'format-name',
          description: 'format-name',
        });
        return;
      }
      if (!Rule.account.email.test(email)) {
        dispatch.account.displayError({
          code: 'format-account',
          description: 'format-account',
        });
        return;
      }
      if (!Rule.account.password.test(password)) {
        dispatch.account.displayError({
          code: 'format-password',
          description: 'format-password',
        });
        return;
      }
      // request network
      dispatch.account.updateLoadingStatus(true);
      try {
        // get token
        const responseForRegister = await Network.instance.post(
          APIMapping.getPathForAPI(APIMapping.api.account.register),
          payload,
        );
        Network.updateToken(responseForRegister.data.token);
        Storage.save(StorageKey.authToken, responseForRegister.data.token);
        // get user information
        const responseForUserInfo = await Network.instance.get(
          APIMapping.getPathForAPI(APIMapping.api.account.info),
        );
        Storage.save(StorageKey.userId, responseForUserInfo.data.id || '');
        Storage.save(
          StorageKey.userEmail,
          responseForUserInfo.data.email || '',
        );
        Storage.save(StorageKey.userName, responseForUserInfo.data.name || '');
        // update login status
        dispatch.account.updateLogInStatus({isLoggedIn: true});
      } catch (e) {
        // clear login status
        Network.updateToken('');
        dispatch.account.updateLogInStatus({isLoggedIn: false});
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
        console.error('error', error.response);
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async login(payload: LoginRequestData) {
      dispatch.account.clearError();
      // check user input error first
      const {email, password} = payload;
      if (email.length === 0) {
        dispatch.account.displayError({
          code: 'empty-account',
          description: 'empty-account',
        });
        return;
      }
      if (password.length === 0) {
        dispatch.account.displayError({
          code: 'empty-password',
          description: 'empty-password',
        });
        return;
      }
      // check format
      if (!Rule.account.email.test(email)) {
        dispatch.account.displayError({
          code: 'format-account',
          description: 'format-account',
        });
        return;
      }
      if (!Rule.account.password.test(password)) {
        dispatch.account.displayError({
          code: 'format-password',
          description: 'format-password',
        });
        return;
      }
      // request network
      dispatch.account.updateLoadingStatus(true);
      try {
        // get token
        const responseForLogin = await Network.instance.post(
          APIMapping.getPathForAPI(APIMapping.api.account.login),
          {...payload, applicationId: '_platform_'},
        );
        console.log('responseForLogin', responseForLogin);
        const {code, data} = responseForLogin.data;
        if (code === 200) {
          Network.updateToken(data.token);
          Storage.save(StorageKey.authToken, data.token);
          // get user information
          Storage.save(StorageKey.userId, data.id || '');
          Storage.save(StorageKey.userEmail, data.email || '');
          Storage.save(StorageKey.userName, data.username || '');
          // update login status
          dispatch.account.updateLogInStatus({isLoggedIn: true});
        }
      } catch (e) {
        // clear login status
        Network.updateToken('');
        dispatch.account.updateLogInStatus({isLoggedIn: false});
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
        console.error('error', error.response);
        if (statusCode === 401) {
          await dispatch.account.logout();
        }
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async logout(payload: undefined) {
      Storage.delete(StorageKey.authToken);
      Network.updateToken('');
      dispatch.account.updateLogInStatus({isLoggedIn: false});
    },
    // handle state changes with impure functions.
    // use async/await for async actions
    async incrementAsync(payload: number, rootState: object) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch.count.increment(payload);
    },
  }),
});

export default account;
