import React from 'react';
import {Box, Heading, Paragraph, List, Nav, Button} from 'grommet';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {IRootState, IDispatch} from '../../store';
import {RouterMap} from '../../router/config';

// components
import Main from '../../components/atoms/mainContainer';
import SideBarComponent from '../../components/views/sideBar';
import {IPanel} from '../../types/panel';

interface Props {
  panel: IRootState['panel'];
  action: {panel: IDispatch['panel']};
}

const PanelListPage = (props: Props) => {
  const history = useHistory();
  React.useEffect(() => {
    props.action.panel.getListData();
  }, [props.action.panel]);

  console.log('@list', props.panel.list);
  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row"
      gap="small">
      <SideBarComponent />
      <Main fill="vertical" flex="grow" overflow="auto" pad="medium">
        <Heading size="small" textAlign="start">
          Panel
        </Heading>
        <Paragraph>
          Panel management page. You can modify spec panel config as needed.
        </Paragraph>
        <List
          data={props.panel.list}
          primaryKey="name"
          secondaryKey="id"
          onClickItem={(event: {item?: any; index?: number | undefined}) => {
            const {item, index} = event;
            console.log(item, index);
            const panelItemData: IPanel = item as IPanel;
            const redirectPathForPanelDetail = RouterMap.panel.detail.generatePathByParams(
              {panelId: panelItemData.id},
            );
            history.push(redirectPathForPanelDetail);
          }}
        />
        <Nav align="end" flex={false} margin={{top: 'small'}}>
          <Button
            label="Create"
            primary
            onClick={() => {
              history.push(RouterMap.panel.create.generatePathByParams());
            }}
          />
        </Nav>
      </Main>
    </Box>
  );
};

/**
 * connect page component
 */

const mapState = (state: IRootState) => {
  return {
    panel: state.panel,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    panel: dispatch.panel,
  },
});

const PageContainer = connect(mapState, mapDispatch)(PanelListPage);

export default PageContainer;
