import React from 'react';
import {
  Box,
  Text,
  Heading,
  Button,
  TextInput,
  Tab,
  CheckBoxGroup,
  Paragraph,
  Select,
} from 'grommet';
import {useParams} from 'react-router-dom';
import Connector, {IComponentProps} from '../../connector';

// types
import {IProduct, IProductUpdate} from '@/types/product';

import MultiLanguageTextWrapper from '@/util/multiLanguageTextWrapper';

import styles from './tabs.module.scss';

interface RouterParamTypes {
  applicationId: string;
  productId: string;
}

function TabComponent(props: IComponentProps) {
  // get application id
  const {applicationId} = useParams<RouterParamTypes>();

  // @ts-ignore
  if (!props.product.product) {
    return null;
  }

  const product: IProduct | undefined = props.product.product as
    | IProduct
    | undefined;

  // config document url components
  const networkConfigDocumentURLDict = MultiLanguageTextWrapper(
    product?.networkConfigDocumentURL,
  );
  const $networkConfigDocumentURL = Object.keys(
    networkConfigDocumentURLDict,
  ).map((key) => {
    const url = networkConfigDocumentURLDict[key];
    return (
      <Box key={key} margin={{bottom: 'medium'}}>
        <Text>Language: {key}</Text>
        <TextInput
          placeholder="https://"
          value={url}
          onChange={(event) => {
            const value = event.target.value;
            props.action.product.updateProductProperties({
              networkConfigDocumentURL: {
                ...networkConfigDocumentURLDict,
                [key]: value,
              },
            });
          }}
        />
        <Box margin={{top: 'small'}}>
          <Text>
            Preview:{' '}
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          </Text>
        </Box>
      </Box>
    );
  });

  return (
    <Tab title="Network Config">
      <Box className={styles.tabContentContainer}>
        <Box align="stretch" justify="center">
          {/**
           * support type
           * */}
          <Box align="stretch" justify="center">
            <Heading level={3} className={styles.groupTitle}>
              Type Config
            </Heading>
            <Paragraph fill>
              <b>Supported Types: </b>
              Set the network configuration supported by the device
            </Paragraph>
            <CheckBoxGroup
              name="support-type"
              valueKey="key"
              value={Object.keys(product?.networkConfigSupport || {}).filter(
                (key) => product?.networkConfigSupport[key],
              )}
              options={Object.keys(product?.networkConfigSupport || {}).map(
                (key) => {
                  return {
                    label: key,
                    key: key,
                  };
                },
              )}
              onChange={(event: any) => {
                let {value} = event;
                value = value as Array<string>;
                if (!product) {
                  return;
                }
                const networkConfigSupport = {...product.networkConfigSupport};
                for (let [key] of Object.entries(networkConfigSupport)) {
                  networkConfigSupport[key] = value.includes(key);
                }
                props.action.product.updateProductProperties({
                  networkConfigSupport,
                });
              }}
            />
            {/**
             * default network config type
             * */}
            <Paragraph fill margin={{top: 'large'}}>
              <b>Default Type: </b>
              Set the default network configuration type
            </Paragraph>
            <Select
              options={Object.keys(product?.networkConfigSupport || {})}
              value={product?.networkConfigSupportDefaultType} //todo: add default properties
              onChange={({option}) => {
                props.action.product.updateProductProperties({
                  networkConfigSupportDefaultType: option,
                });
              }}
            />
          </Box>
          <Box
            align="stretch"
            justify="center"
            margin={{top: 'medium'}}
            border={{color: 'active', side: 'top'}}>
            {/* document config */}
            <Heading level={3} className={styles.groupTitle}>
              Config Document
            </Heading>
            <Paragraph fill>
              <b>URL: </b>
              The webpage url for network config view display in application
            </Paragraph>

            {$networkConfigDocumentURL}
          </Box>
          {/* save button */}
          <Box
            align="end"
            margin={{
              top: 'large',
            }}
            pad={{top: 'medium'}}
            border={{color: 'active', side: 'top'}}>
            <Button
              primary
              label="Save"
              disabled={!product}
              onClick={() => {
                if (!product) {
                  return;
                }
                const updateData: IProductUpdate = {
                  id: product!.id,
                  applicationId,
                  networkConfigSupport: product?.networkConfigSupport,
                  networkConfigDocumentURL: product?.networkConfigDocumentURL,
                  networkConfigSupportDefaultType:
                    product?.networkConfigSupportDefaultType,
                };
                props.action.product.update(updateData);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Tab>
  );
}

export default Connector(TabComponent);
