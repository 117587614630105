import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../atoms/layout";
import Text from "../../atoms/text";
import "./index.scss";

/**
 * NavigationBar View
 */
export interface NavigationBarProps {
  title?: string;
  navigationLeftView?: React.ReactNode;
  navigationRightView?: React.ReactNode;
}

export const NavigationBarHeight = 48;

const NavigationBar = (props: NavigationBarProps) => {
  const $title = (
    <Text oneLine={true} className="navigation-bar-title">
      {props.title}
    </Text>
  );
  const $leftView = props.navigationLeftView ? (
    <Layout className="navigation-bar-left-container">
      {props.navigationLeftView}
    </Layout>
  ) : (
    <Layout />
  );
  const $rightView = props.navigationRightView ? (
    <Layout className="navigation-bar-right-container">
      {props.navigationRightView}
    </Layout>
  ) : (
    <Layout />
  );
  return (
    <Layout direction="row" className="navigation-bar-container">
      {$leftView}
      {$title}
      {$rightView}
    </Layout>
  );
};

/**
 * NavigationBarButton
 */
interface NavigationBarButtonProps {
  title: string;
  link?: string;
  onPress?: () => void;
}

export const NavigationBarButton = (props: NavigationBarButtonProps) => {
  const textClassName =
    props.link || props.onPress
      ? "navigation-bar-button-title-with-link"
      : "navigation-bar-button-title";
  const $button = (
    <Layout
      onPress={props.onPress}
      disableSelection={!!(props.link || props.onPress)}
    >
      <Text className={textClassName}>{props.title}</Text>
    </Layout>
  );
  if (props.link) {
    return <Link to={props.link}>{$button}</Link>;
  }
  return $button;
};

export default NavigationBar;
