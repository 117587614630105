import React from 'react';
import {
  Box,
  Heading,
  Button,
  TextInput,
  Tab,
  Paragraph,
  Select,
} from 'grommet';
import {useParams} from 'react-router-dom';
import Connector, {IComponentProps} from '../../connector';

// types
import {IProduct, IProductUpdate} from '@/types/product';

import styles from './tabs.module.scss';

interface RouterParamTypes {
  applicationId: string;
  productId: string;
}

function TabComponent(props: IComponentProps) {
  // get application id
  const {applicationId} = useParams<RouterParamTypes>();

  // @ts-ignore
  if (!props.product.product) {
    return null;
  }

  const product: IProduct | undefined = props.product.product as
    | IProduct
    | undefined;

  const supportPlatforms = [
    {
      label: 'Tuya',
      value: 'tuya',
    },
    {
      label: 'IOT-CAT',
      value: 'iot-cat',
    },
  ];

  return (
    <Tab title="Platfrom Config">
      <Box className={styles.tabContentContainer}>
        <Box align="stretch" justify="center">
          {/**
           * support type
           * */}
          <Box align="stretch" justify="center">
            <Heading level={3} className={styles.groupTitle}>
              Platform Info
            </Heading>
            {/**
             * default network config type
             * */}
            <Paragraph fill>
              <b>Binding Platform: </b>
              Select the platform for product binding
            </Paragraph>
            <Select
              options={supportPlatforms}
              labelKey="label"
              valueKey="value"
              value={
                supportPlatforms.filter(
                  (item) => item.value === product?.platformInfo.platform,
                )[0]
              } //todo: add default properties
              onChange={({option}) => {
                if (!product) {
                  return;
                }
                props.action.product.updateProductProperties({
                  platformInfo: {
                    ...product.platformInfo,
                    platform: option.value,
                  },
                });
              }}
            />
          </Box>
          <Box
            align="stretch"
            justify="center"
            margin={{top: 'medium'}}
            border={{color: 'active', side: 'top'}}>
            {/* document config */}
            <Heading level={3} className={styles.groupTitle}>
              Extra Data
            </Heading>
            <Paragraph fill>
              <b>Product Key: </b>
            </Paragraph>
            <TextInput
              placeholder=""
              value={product?.platformInfo.extraData.productKey}
              onChange={(event) => {
                if (!product) {
                  return;
                }
                const value = event.target.value;
                props.action.product.updateProductProperties({
                  platformInfo: {
                    ...product.platformInfo,
                    extraData: {
                      ...product.platformInfo.extraData,
                      productKey: value,
                    },
                  },
                });
              }}
            />
          </Box>
          {/* save button */}
          <Box
            align="end"
            margin={{
              top: 'large',
            }}
            pad={{top: 'medium'}}
            border={{color: 'active', side: 'top'}}>
            <Button
              primary
              label="Save"
              disabled={!product}
              onClick={() => {
                if (!product) {
                  return;
                }
                const updateData: IProductUpdate = {
                  id: product!.id,
                  applicationId,
                  platformInfo: product?.platformInfo,
                };
                props.action.product.update(updateData);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Tab>
  );
}

export default Connector(TabComponent);
