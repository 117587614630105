import React from "react";

interface ViewportProviderProps {
  children: React.ReactNode;
}

interface ViewPortContext {
  width: number;
  height: number;
}

const viewportContext = React.createContext<ViewPortContext>({
  width: window.innerWidth,
  height: window.innerHeight,
});

const ViewportProvider = (props: ViewportProviderProps) => {
  // 顺带监听下高度，备用
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {props.children}
    </viewportContext.Provider>
  );
};

const Viewport = {
  Context: viewportContext,
  Provider: ViewportProvider,
};

export default Viewport;
