const accountResources = {
    zh: {
        translation: {
            // basic
            'diary-title': '标题',
            'diary-title-placeholder': '日记条目标题...',
            'diary-content': '内容',
            'diary-content-placeholder': '日记条目内容...',
            'diary-submit': '提交',
            // page names
            'diary-page-name-list': '我的日记',
            'diary-page-name-create': '新日记条目',
            // errors
            'diary-error-empty-title': '标题为空',
            'diary-error-format-title': '标题格式错误，长度应在 1-120 之间',
            'diary-error-empty-content': '内容为空',
            'diary-error-format-content': '内容格式错误，长度应大于 4',
            'diary-error-400': '标题或内容格式错误',
            'diary-error-401': '认证失效',
            'diary-error-500': '服务错误，请稍后重试',
            'diary-error-404': '找不到相关内容',
        }
    }
}

export default accountResources