import {Box, Text, Heading, CheckBox, Tab} from 'grommet';
import {useParams} from 'react-router-dom';
import Connector, {IComponentProps} from '../../connector';

// types
import {IProduct, IProductUpdate} from '@/types/product';

// utils
import time from '@/util/time';

import styles from './tabs.module.scss';

interface RouterParamTypes {
  applicationId: string;
  productId: string;
}

function TabComponent(props: IComponentProps) {
  // get application id
  const {applicationId} = useParams<RouterParamTypes>();
  // @ts-ignore
  if (!props.product.product) {
    return null;
  }

  const product: IProduct | undefined = props.product.product as
    | IProduct
    | undefined;
  return (
    <Tab title="Basic Info">
      <Box className="pt-6 self-stretch w-full">
        <Heading level={3} className={styles.groupTitle}>
          Basic
        </Heading>
        <Text className={styles.basicInfoItem}>Id: {product?.id}</Text>
        <Text className={styles.basicInfoItem}>
          Owner: {product?.ownerName}
        </Text>
        <Text className={styles.basicInfoItem}>
          Create at: {product && time.format(product.createTime)}
        </Text>
        <Text className={styles.basicInfoItem}>
          Update at: {product && time.format(product.updateTime)}
        </Text>
        <Box
          align="stretch"
          justify="center"
          margin={{top: 'medium'}}
          border={{color: 'active', side: 'top'}}>
          <Heading level={3} className={styles.groupTitle}>
            Publish Status
          </Heading>
          <Box
            align="center"
            justify="between"
            direction="row"
            margin={{top: 'medium', bottom: 'medium'}}>
            <Text>Published: {product?.published.toString()}</Text>
            <CheckBox
              checked={product?.published}
              onChange={(event) => {
                if (!product) {
                  return;
                }

                const updateData: IProductUpdate = {
                  id: product.id,
                  applicationId: applicationId,
                  published: event.target.checked,
                };
                props.action.product.update(updateData);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Tab>
  );
}

export default Connector(TabComponent);
