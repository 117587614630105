import React from 'react';
import {
  Box,
  Heading,
  Button,
  FormField,
  TextInput,
  TextArea,
  RadioButtonGroup,
} from 'grommet';
import {connect} from 'react-redux';
// import {useHistory} from 'react-router-dom';
import {IRootState, IDispatch} from '../../store';
// import {RouterMap} from '../../router/config';

// components
import Main from '../../components/atoms/mainContainer';
import SideBarComponent from '../../components/views/sideBar';
// types
import {IPanelCreate, TPanelType} from '../../types/panel';

interface Props {
  panel: IRootState['panel'];
  action: {panel: IDispatch['panel']};
}

const PanelCreatePage = (props: Props) => {
  // state
  const [panelName, setPanelName] = React.useState<string>('');
  const [panelDescription, setPanelDescription] = React.useState<string>('');
  const [panelType, setPanelType] = React.useState<TPanelType>('react-native');
  // const history = useHistory();
  // effects
  React.useEffect(() => {}, []);

  // actions
  const doCreateAction = async () => {
    const createData: IPanelCreate = {
      name: panelName,
      description: panelDescription,
      type: panelType,
    };
    console.log('@createData', createData);
    await props.action.panel.create(createData);
  };

  // subcomponents
  const $formContent = (
    <Box
      fill="vertical"
      overflow="auto"
      align="stretch"
      flex="grow"
      gap="small">
      <FormField label="Panel Name">
        <TextInput
          placeholder="panel name"
          value={panelName}
          onChange={(event) => setPanelName(event.target.value)}
        />
      </FormField>
      <FormField label="Description">
        <TextArea
          placeholder="panel description"
          value={panelDescription}
          onChange={(event) => setPanelDescription(event.target.value)}
        />
      </FormField>
      <FormField label="Type">
        <RadioButtonGroup
          name="type"
          options={[
            {label: 'React Native', value: 'react-native'},
            {label: 'HTML5', value: 'HTML5'},
          ]}
          value={panelType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPanelType(event.target.value as TPanelType)
          }
        />
      </FormField>
      <Box align="end" justify="center">
        <Button label="Create" primary onClick={doCreateAction} />
      </Box>
    </Box>
  );

  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row"
      gap="small">
      <SideBarComponent />
      <Main fill="vertical" flex="grow" overflow="auto" pad="medium">
        <Heading size="small" textAlign="start">
          Create Panel
        </Heading>
        {$formContent}
      </Main>
    </Box>
  );
};

/**
 * connect page component
 */

const mapState = (state: IRootState) => {
  return {
    panel: state.panel,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    panel: dispatch.panel,
  },
});

const PageContainer = connect(mapState, mapDispatch)(PanelCreatePage);

export default PageContainer;
