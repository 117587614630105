import React from 'react';
import {Box, Tab, Button} from 'grommet';
import {useHistory} from 'react-router-dom';
import Connector, {IComponentProps} from '../connector';
// import {useMountedState} from 'react-use';
import {RouterMap} from '@/router/config';
import {useMount} from 'react-use';

// contexts

// utils
import UtilMultiLanguageTextWrapper from '@/util/multiLanguageTextWrapper';

// components

import styles from './tabs.module.scss';
import {IProduct} from '@/types/product';

// interface RouterParamTypes {
//   applicationId: string;
// }

interface IProps {
  applicationId: string;
}

const Page = (props: IComponentProps & IProps) => {
  // get application id
  const {applicationId} = props;
  // const isMounted = useMountedState();
  const history = useHistory();

  useMount(() => {
    // console.log('#applicationId', applicationId)
    applicationId &&
      props.action.product.getListDataForApplication({applicationId});
  });

  // content list
  const $list = (props.product.list||[]).map((item: IProduct) => {
    const _names = UtilMultiLanguageTextWrapper(item.names);
    const nameString = [_names.en, _names.zh].filter((x) => !!x).join(' / ');
    const $names = nameString && (
      <div className="text-sm text-gray-700 px-2 py-1 mr-1 rounded-sm bg-gray-100">
        {nameString}
      </div>
    );
    // platform tag
    let platformTagColor = '';
    switch (item.platformInfo?.platform.toLowerCase()) {
      case 'tuya':
        platformTagColor = 'bg-yellow-200';
        break;
      case 'iot-cat':
        platformTagColor = 'bg-purple-200';
        break;
      default:
        break;
    }
    const $platform = item.platformInfo?.platform && (
      <div
        className={[
          'text-sm text-gray-700 px-2 py-1 mr-1 rounded-sm',
          platformTagColor,
        ]
          .filter((x) => !!x)
          .join(' ')}>
        {item.platformInfo?.platform.toUpperCase()}
      </div>
    );
    const $tags = $platform && $names && (
      <div className="flex-row mb-2">
        {$platform}
        {$names}
      </div>
    );
    const $productId = (
      <div className="transition text-sm py-1 px-2 bg-gray-100 text-gray-600 font-semibold rounded hover:bg-gray-300 hover:text-gray-800">
        Product id: {item.id}
      </div>
    );
    return (
      <div
        key={item.id}
        className="flex-row transition-all transform py-4 px-4 my-2 shadow-sm border-gray-200 border rounded scale-100 bg-gray-50 hover:bg-white hover:shadow-xl">
        <div className="flex-row content-between justify-between flex-grow">
          {/* left container */}
          <div className="flex-col">
            <div className="mb-2 text-gray-800 text-2xl">{item.name}</div>
            {$tags}
            {$productId}
          </div>
          {/* right container */}
          <div className="flex-row justify-items-end items-start">
            <div
              className="transition text-sm py-1 px-2 bg-gray-200 text-gray-600 font-semibold rounded hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 cursor-pointer"
              onClick={() => {
                // console.log(item, index);
                const redirectPath =
                  RouterMap.application.product.detail.generatePathByParams({
                    applicationId: applicationId,
                    productId: item.id,
                  });
                history.push(redirectPath);
              }}>
              Settings
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <Tab title="Products">
      <Box className={styles.tabContentContainer}>
        {$list}
        <Button
          label="Create new product"
          alignSelf="start"
          onClick={() => {
            history.push(
              RouterMap.application.product.create.generatePathByParams({
                applicationId,
              }),
            );
          }}
          margin={{top: 'medium'}}
        />
      </Box>
    </Tab>
  );
};

export default Connector(Page);
