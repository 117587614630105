const accountResources = {
    zh: {
        translation: {
            // basic
            'account-name': '名字',
            'account-name-placeholder': '名字...',
            'account-mail': '邮件地址',
            'account-mail-placeholder': '邮件地址...',
            'account-password': '密码',
            'account-password-placeholder': '密码...',
            'account-register': '注册',
            'account-log-in': '登陆',
            'account-log-out': '退出',
            'account-no-account-go-register': '没有账号？去注册',
            'account-have-account-go-login': '已有账号？去登陆',
            // errors
            'account-error-empty-name': '名字为空',
            'account-error-format-name': '名字格式错误，应为 4-30 位长度',
            'account-error-empty-account': '账号为空',
            'account-error-format-account': '账号格式错误，应为邮箱地址',
            'account-error-empty-password': '密码为空',
            'account-error-format-password': '密码格式错误，仅支持英文字母、数字、英文下划线、点、英文连接符，长度6-30位',
            'account-error-400': '账户或密码格式错误',
            'account-error-401': '认证失效',
            'account-error-500': '服务错误，请稍后重试',
            'account-error-not-found-name': '未知用户名',
        }
    }
}

export default accountResources