import React from 'react';
import {
  Box,
  Main,
  Header,
  Heading,
  Form,
  FormField,
  TextInput,
  Button,
  Footer,
  Paragraph,
} from 'grommet';
import {connect} from 'react-redux';
import ErrorTip from '../../components/views/errorTip';

import {IRootState, IDispatch} from '../../store';

interface LoginPageProps {
  account: IRootState['account'];
  action: {account: IDispatch['account']};
}

const Page = (props: LoginPageProps) => {
  const [account, setAccount] = React.useState('');
  const [password, setPassword] = React.useState('');
  const doLogIn = async () => {
    console.log(`account: ${account}, password: ${password}`);
    await props.action.account.login({email: account, password});
  };

  const $errorTip = props.account.error && (
    <ErrorTip
      description={props.account.errorDescription}
      code={props.account.errorCode}
      onConfirm={props.action.account.clearError}
    />
  );

  return (
    <Box fill="vertical" overflow="auto" align="center" flex="grow">
      <Main
        flex="grow"
        overflow="auto"
        pad={{horizontal: 'large'}}
        justify="center">
        <Header
          align="center"
          direction="row"
          flex={false}
          justify="between"
          gap="medium">
          <Heading>Log In</Heading>
        </Header>
        <Form>
          <FormField label="Account">
            <TextInput
              placeholder="enter email"
              onChange={(event) => {
                setAccount(event.target.value);
              }}
              value={account}
            />
          </FormField>
          <FormField label="Password">
            <TextInput
              placeholder="enter password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              value={password}
              type="password"
            />
          </FormField>
          <Button
            label="Done"
            active={false}
            primary
            hoverIndicator
            type="button"
            onClick={doLogIn}
          />
        </Form>
        <Footer
          align="center"
          direction="column"
          flex={false}
          justify="between"
          gap="medium"
          margin={{top: 'large'}}>
          <Paragraph size="medium">
            If you do not have account, please contact our commissioner.
          </Paragraph>
        </Footer>
      </Main>
      {$errorTip}
    </Box>
  );
};

/**
 * connect page component
 */

const mapState = (state: IRootState) => {
  return {
    account: state.account,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    account: dispatch.account,
  },
});

const LoginPageContainer = connect(mapState, mapDispatch)(Page);

export default LoginPageContainer;
