import React, { CSSProperties } from "react";
import Text from "../../atoms/text";
import Input, { InputProps } from "../../atoms/input";
import Layout from "../../atoms/layout";
import "./index.scss";

interface TextFieldProps extends InputProps {
  title?: string;
  inputStyle?: CSSProperties;
  inputFocusStyle?: CSSProperties;
  size?: "normal" | "big";
  className?: string;
}

const TextField = (props: TextFieldProps) => {
  const { size = "normal", className = "" } = props;
  const $label = (
    <Text type={size === "normal" ? "label" : "big-label"} className="form-field-label">
      {props.title}
    </Text>
  );
  const $input = (
    <Layout>
      <Input
        className="form-field-input"
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        style={props.inputStyle}
        focusStyle={props.inputFocusStyle}
        type={props.type}
        value={props.value}
      />
    </Layout>
  );

  const containerClassName = `form-field-container ${className}`;

  return (
    <Layout
      direction="column"
      className={containerClassName}
      style={props.style}
    >
      {$label}
      {$input}
    </Layout>
  );
};

export default TextField;
