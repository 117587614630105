const prefix = 'v1/';

export const API = {
  // account
  account: {
    register: 'auth/register',
    login: 'account/token',
    info: 'account/user?id=:id&applicationId=:applicationId',
  },
  application: {
    list: 'application/list',
    item: 'application/item?id=:id',
    create: 'application/item',
    update: 'application/item',
    product: 'application/product',
  },
  product: {
    list: 'product/list',
    listByIds: 'product/list?ids=:ids',
    item: 'product/item?id=:id',
    create: 'product/item',
    update: 'product/item',
  },
  panel: {
    create: 'panel/item',
    createVersion: 'panel/version',
    update: 'panel/item',
    item: 'panel/item?id=:id',
    list: 'panel/list',
  },
  resource: {
    uploadAuthorization: 'resource/uploadAuthorization',
  },
  statistics: {
    usage: 'statistics/usage?queryKey=:queryKey'
  },
  // diary
  diary: {
    list: 'users/me/posts',
    detail: 'posts/:id',
    create: 'posts',
  },
};

type APISubPath = string;
type GetPathMethod = (
  api: APISubPath,
  params?: {[key: string]: string},
) => string;

export const GetPathForAPI: GetPathMethod = (
  api: APISubPath,
  params?: {[key: string]: string},
) => {
  let subPath = api;
  if (params) {
    Object.keys(params).forEach((key) => {
      subPath = subPath.replace(new RegExp(`:${key}`, 'ig'), params[key]);
    });
  }

  return `${prefix}${subPath}`;
};

const APIMapping = {api: API, getPathForAPI: GetPathForAPI};

export default APIMapping;
