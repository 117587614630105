/**
 * return multi language value with default language keys
 */

const func = (multiLanguageTextValueDict: {[key: string]: string} | undefined = {}):{[key: string]: string}  => {
    if (typeof multiLanguageTextValueDict !== 'object') {
        multiLanguageTextValueDict = {}
    }
  return {
    en: '',
    zh: '',
    ...multiLanguageTextValueDict,
  };
};

export default func