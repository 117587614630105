import React, {ReactNode} from 'react';
import {Box, Tab, Button, TextInput, Paragraph, Heading} from 'grommet';
// import {useParams} from 'react-router-dom';
import Connector, {IComponentProps} from '../connector';
// import {useMountedState} from 'react-use';

// contexts

// utils
// components

import styles from './tabs.module.scss';

// interface RouterParamTypes {
//   id: string;
// }

interface IProps {
  applicationId: string;
}

const Group = (props: {children: ReactNode | ReactNode[]}) => {
  return (
    <Box
      align="stretch"
      justify="center"
      margin={{top: 'medium'}}
      border={{color: 'active', side: 'top'}}>
      {props.children}
    </Box>
  );
};

const GroupHeader = (props: {title: string}) => (
  <Heading level={3} className={styles.groupTitle}>
    {props.title}
  </Heading>
);

const InputLabel = (props: {title: string; description?: string}) => (
  <Paragraph fill>
    <b>{props.title}: </b> {props.description}
  </Paragraph>
);

const TextInputComponent = (props: {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}) => {
  return (
    <TextInput
      placeholder={props.placeholder}
      value={props.value}
      onChange={(event) => {
        const value = event.target.value;
        if (props.onChange) {
          props.onChange(value);
        }
      }}
    />
  );
};

const Page = (props: IComponentProps & IProps) => {
  // get application id
  // const {id} = useParams<RouterParamTypes>();
  // const isMounted = useMountedState();

  const {applicationId} = props;
  const application = props.application.application;

  const $name = (
    <Group>
      <GroupHeader title="Basic Config" />
      {/* name */}
      <InputLabel title="Application Name" />
      <TextInputComponent
        value={props.application.application?.name}
        onChange={(value: string) => {
          if (!applicationId) {
            return;
          }
          props.action.application.updateProperties({
            id: applicationId,
            name: value,
          });
        }}
      />
    </Group>
  );

  const $protocol = (
    <Group>
      <GroupHeader title="Protocol Config" />
      {/* user agreement */}
      <InputLabel
        title="User Agreement"
        description="This page will display in application"
      />
      <TextInputComponent
        value={props.application.application?.userAgreementURL}
        onChange={(value: string) => {
          if (!applicationId || !application) {
            return;
          }
          props.action.application.updateProperties({
            id: applicationId,
            userAgreementURL: value,
          });
        }}
      />
      {/* private policy */}
      <InputLabel
        title="Privacy Policy"
        description="This page will display in application"
      />
      <TextInputComponent
        value={props.application.application?.privacyPolicy}
        onChange={(value: string) => {
          if (!applicationId || !application) {
            return;
          }
          props.action.application.updateProperties({
            id: applicationId,
            privacyPolicy: value,
          });
        }}
      />
    </Group>
  );

  const $saveButton = (
    <Box
      align="end"
      margin={{
        top: 'large',
      }}
      pad={{top: 'medium'}}
      border={{color: 'active', side: 'top'}}>
      <Button
        primary
        label="Save"
        disabled={!applicationId}
        onClick={() => {
          if (!applicationId || !application) {
            return;
          }
          props.action.application.update({
            id: applicationId,
            name: application.name,
            userAgreementURL: application.userAgreementURL,
            privacyPolicy: application.privacyPolicy,
          });
        }}
      />
    </Box>
  );

  return (
    <Tab title="Config">
      <Box className={styles.tabContentContainer}>
        {$name}
        {$protocol}
        {$saveButton}
      </Box>
    </Tab>
  );
};

export default Connector(Page);
