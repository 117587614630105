import React from 'react';
import {Box, Heading, Paragraph, Button} from 'grommet';
import styles from './errorTip.module.scss';

interface ErrorTipProps {
  title?: string;
  code?: string | number;
  description: string;
  onConfirm?: () => {};
}

const ErrorTip = (props: ErrorTipProps) => {
  return (
    <Box className={styles.errorTipContainer}>
      <Box
        overflow="auto"
        flex="grow"
        pad="medium"
        className={styles.errorTipContent}>
        <Heading className={styles.title}>
          {props.title || 'Error'}
          {/* {props.code ? `(${props.code})` : ''} */}
        </Heading>
        <Paragraph>{props.description || 'UNKNOWN ERROR'}</Paragraph>
        <Box align="start" justify="center">
          <Button label="OK" active={false} primary onClick={props.onConfirm} />
        </Box>
      </Box>
    </Box>
  );
};
export default ErrorTip;
