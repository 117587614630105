import {createModel, RematchDispatch} from '@rematch/core';
import {AxiosError} from 'axios';
import Network from '../util/network';
import APIMapping from '../util/APIMapping';
import {RootModel} from './index';
import history from '../util/history';

/**
 * types
 */
import {
  Application as IApplication,
  IApplicationCreate,
  IApplicationUpdate,
} from '../types/application';
import {RouterMap} from '../router/config';

interface ErrorData {
  code: number | string;
  description: string;
}

type TApplicationList = Array<IApplication>;

export interface ApplicationState {
  list: TApplicationList;
  limitForPage: number;
  currentCursor: string;
  listComplete: boolean;
  application?: IApplication; // current application which is selected
  // status
  loading: boolean;
  error: boolean;
  // others
  errorCode: number;
  errorDescription: string;
}

const initialState: ApplicationState = {
  // basic information
  list: [],
  limitForPage: 10,
  currentCursor: '',
  listComplete: false,
  application: undefined,
  // status
  loading: false,
  error: false,
  // others
  errorCode: 0,
  errorDescription: '',
};
// @ts-ignore
const application = createModel<RootModel>()({
  // initial state
  state: initialState,
  reducers: {
    // update common status
    updateLoadingStatus(state: ApplicationState, payload: boolean) {
      return {...state, loading: payload};
    },
    displayError(state: ApplicationState, payload: ErrorData) {
      return {
        ...state,
        error: true,
        errorCode: payload.code,
        errorDescription: payload.description,
      } as ApplicationState;
    },
    clearError(state: ApplicationState, payload: undefined) {
      return {...state, error: false, errorCode: 0, errorDescription: ''};
    },
    // update list
    clearList(state: ApplicationState, payload: undefined) {
      return {...state, list: []};
    },
    appendList(state: ApplicationState, list: TApplicationList) {
      return {...state, list: [...state.list, ...list]};
    },
    replaceList(state: ApplicationState, list: TApplicationList) {
      return {...state, list};
    },
    // update application
    updateApplication(state: ApplicationState, application: IApplication) {
      return {...state, application};
    },
    updateProperties(state: ApplicationState, properties: IApplicationUpdate) {
      if (!state.application) {
        return state;
      }
      return {
        ...state,
        application: {
          ...state.application,
          ...properties,
        },
      };
    },
  },
  effects: (dispatch: RematchDispatch<RootModel>) => ({
    async create(payload: IApplicationCreate) {
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.post(
          APIMapping.getPathForAPI(APIMapping.api.application.create),
          payload,
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          history.push(
            RouterMap.application.detail.generatePathByParams({id: data.id}),
          );
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async getListData(payload: undefined) {
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.get(
          APIMapping.getPathForAPI(APIMapping.api.application.list),
          {},
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          dispatch.application.replaceList(data.list);
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async getApplicationDetail(id: string) {
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.get(
          APIMapping.getPathForAPI(APIMapping.api.application.item, {id}),
          {},
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          dispatch.application.updateApplication(data as IApplication);
        } else {
          dispatch.account.displayError({code: code, description});
        }
        console.log('response', response);
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async update(payload: IApplicationUpdate) {
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.put(
          APIMapping.getPathForAPI(APIMapping.api.application.update),
          payload,
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          dispatch.application.updateApplication(data as IApplication);
        } else {
          dispatch.account.displayError({code: code, description});
        }
        console.log('application update response', response);
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
  }),
});

export default application;
