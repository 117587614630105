import React, { CSSProperties } from "react";
import "./index.scss";

interface TextProps {
  children: React.ReactNode;
  style?: CSSProperties;
  type?:
    | "label"
    | "title"
    | "content"
    | "error"
    | "link"
    | "link-highlight"
    | "big-label";
  center?: boolean;
  oneLine?: boolean;
  className?: string;
}

const Text = (props: TextProps) => {
  const {
    children,
    style = {},
    center = false,
    oneLine = false,
    type,
    className = "",
    ...otherProps
  } = props;

  // get style
  let styleName = type ? `text text-${type}` : "text";
  switch (type) {
    case "link-highlight":
      styleName = `${styleName} link-highlight`;
      break;
    default:
      break;
  }
  if (center) {
    styleName = `${styleName} text-center`;
  }
  if (oneLine) {
    styleName = `${styleName} text-one-line`;
  }
  styleName = `${styleName} ${className}`;

  if (type === "content") {
    return (
      <p className={styleName} style={style} {...otherProps}>
        {children}
      </p>
    );
  } else {
    return (
      <span className={styleName} style={style} {...otherProps}>
        {children}
      </span>
    );
  }
};

export default Text;
