import React from 'react';
import {Box, Button} from 'grommet';
import {Link, useLocation} from 'react-router-dom';
import {RouterMap} from '../../../../router/config';
interface INavBarComponentProps {
  applicationId: string;
}

const NavBarComponent = (props: INavBarComponentProps) => {
  const location = useLocation();
  const [path, setPath] = React.useState('/');
  React.useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <Box align="stretch" justify="start" gap="xsmall" direction="row">
      <Link
        to={RouterMap.application.detail.generatePathByParams({
          id: props.applicationId!,
        })}>
        <Button
          label="Statistics"
          primary={path.indexOf('/application/detail') >= 0}
        />
      </Link>
      <Link
        to={RouterMap.application.product.list.generatePathByParams({
          applicationId: props.applicationId!,
        })}>
        <Button
          label="Products"
          primary={path.indexOf('/application/product/list') >= 0}
        />
      </Link>
      <Button label="Users" disabled={true} />
    </Box>
  );
};

export default NavBarComponent;
