import React from 'react';
import {BoxProps} from 'grommet';
import styles from './mainContainer.module.scss';

function MainContainer(props: {children: React.ReactNode} & BoxProps) {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentContainer}>{props.children}</div>
    </div>
  );
}

export default MainContainer;
