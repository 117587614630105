import {ElementType} from 'react';
// pages
// account
import AccountLoginPage from '../pages/account/login';
import AccountRegisterPage from '../pages/account/register';
// diary
// import ListPage from '../pages/diary/list';
// import CreatePage from '../pages/diary/create';
// import DetailPage from '../pages/diary/detail';
// welcome
// import WelcomPage from '../pages/welcome';
// application
import ApplicationCreatePage from '../pages/application/create';
import ApplicationListPage from '../pages/application/list';
import ApplicationDetailPage from '../pages/application/detail';
import ApplicationProductListPage from '../pages/application/detail/productList';
import ApplicationProductDetailPage from '../pages/application/detail/productDetail';
import ApplicationProductCreatePage from '../pages/application/detail/productCreate';
// panel
import PanelListPage from '../pages/panel/list';
import PanelCreatePage from '../pages/panel/create';
import PanelDetailPage from '../pages/panel/detail';
import PanelCreateVersionPage from '../pages/panel/createVersion';

/**
 * interfaces
 */
interface IRouterConfigItem {
  path: string;
  generatePathByParams: (params: any) => string;
  page: ElementType;
  rule?: {
    onlyForGuest?: boolean;
    onlyForMember?: boolean;
  };
}

interface IConfigMap {
  [key: string]: IRouterConfigItem | IConfigMap;
}

/**
 * map config: IConfigMap
 */
export const RouterMap = {
  account: {
    login: {
      path: '/login',
      generatePathByParams: () => '/login',
      page: AccountLoginPage,
      rule: {
        onlyForGuest: true,
      },
    },
    register: {
      path: '/register',
      generatePathByParams: () => '/register',
      page: AccountRegisterPage,
      rule: {
        onlyForGuest: true,
      },
    },
  },
  application: {
    create: {
      path: '/application/create',
      generatePathByParams: () => '/application/create',
      page: ApplicationCreatePage,
      rule: {onlyForMember: true},
    },
    list: {
      path: '/application/list',
      generatePathByParams: () => '/application/list',
      page: ApplicationListPage,
      rule: {onlyForMember: true},
    },
    detail: {
      path: '/application/detail/:id',
      generatePathByParams: (params: {id: string}) =>
        `/application/detail/${params.id}`,
      page: ApplicationDetailPage,
      rule: {onlyForMember: true},
    },
    product: {
      list: {
        path: '/application/product/list/:applicationId',
        generatePathByParams: (params: {applicationId: string}) =>
          `/application/product/list/${params.applicationId}`,
        page: ApplicationProductListPage,
        rule: {onlyForMember: true},
      },
      detail: {
        path: '/application/product/detail/:applicationId/:productId',
        generatePathByParams: (params: {
          applicationId: string;
          productId: string;
        }) =>
          `/application/product/detail/${params.applicationId}/${params.productId}`,
        page: ApplicationProductDetailPage,
        rule: {onlyForMember: true},
      },
      create: {
        path: '/application/product/create/:applicationId',
        generatePathByParams: (params: {applicationId: string}) =>
          `/application/product/create/${params.applicationId}`,
        page: ApplicationProductCreatePage,
        rule: {onlyForMember: true},
      },
    },
  },
  panel: {
    list: {
      path: '/panel/list',
      generatePathByParams: () => '/panel/list',
      page: PanelListPage,
      rule: {onlyForMember: true},
    },
    detail: {
      path: '/panel/detail/:panelId',
      generatePathByParams: (params: {panelId: string}) =>
        `/panel/detail/${params.panelId}`,
      page: PanelDetailPage,
      rule: {onlyForMember: true},
    },
    create: {
      path: '/panel/create',
      generatePathByParams: () => '/panel/create',
      page: PanelCreatePage,
      rule: {onlyForMember: true},
    },
    createVersion: {
      path: '/panel/create-version/:panelId',
      generatePathByParams: (params: {panelId: string}) =>
        `/panel/create-version/${params.panelId}`,
      page: PanelCreateVersionPage,
      rule: {onlyForMember: true},
    },
  },
};

/**
 * generate router config array
 */
const routerConfig: Array<IRouterConfigItem> = [];
const parseConfigMapItem = (configMapItem: IRouterConfigItem | IConfigMap) => {
  const isIConfigMap =
    configMapItem.hasOwnProperty('path') &&
    configMapItem.hasOwnProperty('generatePathByParams') &&
    configMapItem.hasOwnProperty('page') &&
    configMapItem.hasOwnProperty('rule');

  if (isIConfigMap) {
    routerConfig.push(configMapItem as IRouterConfigItem);
  } else {
    for (let item in configMapItem) {
      parseConfigMapItem((configMapItem as any)[item]);
    }
  }
};
parseConfigMapItem(RouterMap);
// console.log('configMap', configMap, routerConfig);

export default routerConfig;
