import {createModel, RematchDispatch} from '@rematch/core';
import {AxiosError} from 'axios';
import Network from '../util/network';
import APIMapping from '../util/APIMapping';
import {RootModel} from './index';
import history from '../util/history';
import store from '../store';

/**
 * types
 */
import {
  IProduct,
  IProductCreateForApplication,
  IProductUpdate,
} from '../types/product';
import {RouterMap} from '../router/config';

interface ErrorData {
  code: number | string;
  description: string;
}

type TProductList = Array<IProduct>;

export interface ProductState {
  list: TProductList;
  product?: IProduct; // current product which is selected
  // status
  loading: boolean;
  error: boolean;
  // others
  errorCode: number;
  errorDescription: string;
}

const initialState: ProductState = {
  // basic information
  list: [],
  product: undefined,
  // status
  loading: false,
  error: false,
  // others
  errorCode: 0,
  errorDescription: '',
};
// @ts-ignore
const product = createModel<RootModel>()({
  // initial state
  state: initialState,
  reducers: {
    // update common status
    updateLoadingStatus(state: ProductState, payload: boolean) {
      return {...state, loading: payload};
    },
    displayError(state: ProductState, payload: ErrorData) {
      return {
        ...state,
        error: true,
        errorCode: payload.code,
        errorDescription: payload.description,
      } as ProductState;
    },
    clearError(state: ProductState, payload: undefined) {
      return {...state, error: false, errorCode: 0, errorDescription: ''};
    },
    // update list
    clearList(state: ProductState, payload: undefined) {
      return {...state, list: []};
    },
    appendList(state: ProductState, list: TProductList) {
      return {...state, list: [...state.list, ...list]};
    },
    replaceList(state: ProductState, list: TProductList) {
      return {...state, list};
    },
    // update
    updateProduct(state: ProductState, product: IProduct) {
      return {...state, product};
    },
    clearProduct(state: ProductState) {
      return {...state, product: undefined};
    },
    updateProductProperties(
      state: ProductState,
      productProperties: IProductUpdate,
    ) {
      if (!state.product) {
        return state;
      }
      return {
        ...state,
        product: {
          ...state.product,
          ...productProperties,
        },
      };
    },
  },
  //@ts-ignore
  effects: (dispatch: RematchDispatch<RootModel>) => ({
    async create(payload: IProductCreateForApplication) {
      console.log('@create product params', payload);
      const applicationId = payload.id;
      dispatch.account.updateLoadingStatus(true);
      try {
        // todo: use list by ids
        const response = await Network.instance.post(
          APIMapping.getPathForAPI(APIMapping.api.product.create),
          {
            name: payload.name,
          },
        );
        const {code, description} = response.data;
        if (code === 200) {
          // bind product to application
          const productId = response.data.data.id;
          const {
            data: {code, data, description},
          } = await Network.instance.put(
            APIMapping.getPathForAPI(APIMapping.api.application.product),
            {
              id: applicationId,
              productId: productId,
              action: 'bindProduct',
            },
          );
          console.log('@update application result', code, data, description);
          if (code === 200) {
            await dispatch.product.getListDataForApplication({applicationId});
            // redirect page
            history.push(
              RouterMap.application.product.list.generatePathByParams({
                applicationId,
              }),
            );
          } else {
            dispatch.account.displayError({code: code, description});
          }
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async update(payload: IProductUpdate) {
      const applicationId = payload.applicationId;
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.put(
          APIMapping.getPathForAPI(APIMapping.api.product.update),
          payload,
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          await dispatch.product.updateProduct(data);
          if (payload.panelId) {
            await dispatch.panel.getPanelDetail(payload.panelId);
          }
          // redirect page
          history.push(
            RouterMap.application.product.detail.generatePathByParams({
              applicationId: applicationId,
              productId: payload.id!,
            }),
          );
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    // @ts-ignore
    async getListDataForApplication(
      payload: {applicationId: string},
      rootState: RootModel,
    ) {
      await dispatch.application.getApplicationDetail(payload.applicationId);
      const currentState = store.getState();
      const currentApplicationProductIds: Array<string> =
        currentState.application.application?.productIds || [];
      if (currentApplicationProductIds.length === 0) {
        await dispatch.product.replaceList();
        return;
      }
      // request data
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.post(
          `${APIMapping.getPathForAPI(APIMapping.api.product.list)}`,
          {
            ids: currentApplicationProductIds,
          },
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          dispatch.product.replaceList(data);
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async getProductDetail(id: string) {
      dispatch.account.updateLoadingStatus(true);
      dispatch.product.clearProduct();
      try {
        const response = await Network.instance.get(
          APIMapping.getPathForAPI(APIMapping.api.product.item, {id}),
          {},
        );
        const {code, data, description} = response.data;
        const productData: IProduct = {...data, ownerName: ''} as IProduct;
        if (code === 200) {
          // query owner data
          const {data: responseForUserInfo} = await Network.instance.get(
            APIMapping.getPathForAPI(APIMapping.api.account.info, {
              id: productData.ownerId,
              applicationId: '_platform_',
            }),
            {},
          );
          if (responseForUserInfo.code === 200) {
            productData.ownerName = responseForUserInfo.data.username;
            dispatch.product.updateProduct(productData as IProduct);
            if ((productData as IProduct).panelId) {
              await dispatch.panel.getPanelDetail(
                (productData as IProduct).panelId,
              );
            }
          } else {
            dispatch.account.displayError({
              code: responseForUserInfo.code,
              description: responseForUserInfo.description,
            });
          }
        } else {
          dispatch.account.displayError({code: code, description});
        }
        console.log('response', response);
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
  }),
});

export default product;
