import React from 'react';
import Button from '../../../components/atoms/button';
import {Box, Nav} from 'grommet';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {IRootState, IDispatch} from '../../../store';
import {RouterMap} from '../../../router/config';

// components
import SideBarComponent from '../../../components/views/sideBar';
import Main from '../../../components/atoms/mainContainer';

// types
import {Application} from '../../../types/application';

interface Props {
  application: IRootState['application'];
  action: {application: IDispatch['application']};
}

const ApplicationListPage = (props: Props) => {
  const history = useHistory();
  React.useEffect(() => {
    props.action.application.getListData();
  }, [props.action.application]);

  // console.log('@list', props.application.list);

  const $list = props.application.list.map((item: Application) => {
    const onPress = () => {
      history.push(`/application/detail/${item.id}`);
    };

    const $id = (
      <div className="transition text-sm py-1 px-2 bg-gray-100 text-gray-600 font-semibold rounded hover:bg-gray-300 hover:text-gray-800">
        Product id: {item.id}
      </div>
    );
    return (
      <div
        key={item.id}
        className="flex-row transition-all transform py-4 px-4 my-2 shadow-sm border-gray-200 border rounded scale-100 bg-white opacity-80 hover:bg-white hover:shadow-xl hover:opacity-100">
        <div className="flex-row content-between justify-between flex-grow">
          {/* left container */}
          <div className="flex-col">
            <div className="mb-2 text-gray-800 text-2xl">{item.name}</div>
            {$id}
          </div>
          {/* right container */}
          <div className="flex-row justify-items-end items-start">
            <div
              className="transition text-sm py-1 px-2 bg-gray-200 text-gray-600 font-semibold rounded hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 cursor-pointer"
              onClick={onPress}>
              Settings
            </div>
          </div>
        </div>
      </div>
    );
  });

  /**
   * create button
   */
  const $createButton = (
    <Button
      title="Create"
      disabled={false}
      onPress={() =>
        history.push(RouterMap.application.create.generatePathByParams())
      }
    />
  );

  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row"
      gap="small">
      <SideBarComponent />
      <Main fill="vertical" flex="grow" overflow="auto" pad="medium">
        <div className="text-4xl pb-6">Application</div>
        <div className="text-lg pb-8">
          Application management panel. You can modify spec application config
          as needed.
        </div>
        {$list}
        <Nav align="end" flex={false} margin={{top: 'small'}}>
          {$createButton}
        </Nav>
      </Main>
    </Box>
  );
};

/**
 * connect page component
 */

const mapState = (state: IRootState) => {
  return {
    application: state.application,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    application: dispatch.application,
  },
});

const PageContainer = connect(mapState, mapDispatch)(ApplicationListPage);

export default PageContainer;
