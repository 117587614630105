import React from 'react';
// import Layout from '../layout';
// import Text from '../text';
import './index.scss';

interface ButtonProps {
  title?: string;
  onPress?: () => void;
  className?: string;
  disabled?: boolean;
}

const Button = (props: ButtonProps) => {
  const basicClassName = 'transition transform select-none px-6 py-2 rounded-full bg-blue-500 border-blue-500 border-2 text-white text-lg cursor-pointer scale-90 hover:bg-white hover:text-blue-500 hover:scale-100 hover:shadow-xl'
  const disabledClassName = props.disabled ? 'bg-gray-500' : ''
  return (
    <div
      className={`${basicClassName} ${disabledClassName} ${props.className} `}
      onClick={props.disabled ? undefined : props.onPress}>
      {props.title}
    </div>
  );
};

export default Button;
