import axios from 'axios';
import Config from '../config';
import Storage, {StorageKey} from './storage';
import store from '@/store';

let token = Storage.get(StorageKey.authToken);

const instance = axios.create({
  baseURL: Config.network.baseURL,
  timeout: Config.network.timeout,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept',
    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE',
    [Config.network.keyForTokenInHeader]: token,
  },
});

instance.interceptors.response.use(
  (response) => {
    console.log('#network instance response', response);
    if (response.data.code === 403) {
      if (response.data.description === 'wrong token value - user token') {
        store.dispatch.account.logout();
      }
    }
    return Promise.resolve(response);
  },
  (error) => {
    console.log('#network instance error', error);
    return Promise.reject(error);
  },
);

/**
 * Update network instance header auth value when user log in or log out
 * @param newToken
 */
const updateToken: (newToken: string) => void = (newToken) => {
  token = newToken;
  instance.defaults.headers[Config.network.keyForTokenInHeader] = token;
};

const Network = {instance, updateToken};

export default Network;
