import React from 'react';
import {
  base,
  Box,
  Heading,
  List,
  Button,
  FormField,
  Text,
  Select,
} from 'grommet';
import {connect} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {IRootState, IDispatch} from '../../store';
import {RouterMap} from '../../router/config';

// components
import MainContainer from '../../components/atoms/mainContainer';
import SideBarComponent from '../../components/views/sideBar';
// types
import {IPanel, IPanelUpdate, IPanelVersionItem} from '../../types/panel';

import styles from './detail.module.scss';

const brandColor: string =
  base.global && base.global.colors && base.global.colors.brand
    ? (base.global.colors.brand as string)
    : '#7D4CDB';
interface RouterParamTypes {
  panelId: string;
}

interface Props {
  panel: IRootState['panel'];
  action: {panel: IDispatch['panel']};
}

const PanelCreatePage = (props: Props) => {
  // path params
  const {panelId} = useParams<RouterParamTypes>();
  // state
  // const [panelName, setPanelName] = React.useState<string>('');
  // const [panelDescription, setPanelDescription] = React.useState<string>('');
  // const [panelType, setPanelType] = React.useState<TPanelType>('react-native');
  const [publishVersion, setPublishVersion] = React.useState<string>('');
  const [devVersion, setDevVersion] = React.useState<string>('');
  const [
    panelVersionDetail,
    setPanelVersionDetail,
  ] = React.useState<IPanelVersionItem | null>(null);
  const history = useHistory();
  // effects
  React.useEffect(() => {
    setupData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    const panel: IPanel = props.panel.panel;
    if (panel) {
      setPublishVersion(panel.publishVersion);
      setDevVersion(panel.devVersion);
    }
  }, [props.panel.panel]);

  // actions
  /*
  const doCreateAction = async () => {
    const createData: IPanelCreate = {
      name: panelName,
      description: panelDescription,
      type: panelType,
    };
    console.log('@createData', createData);
    await props.action.panel.create(createData);
  };
  */

  const updatePanelVersion = async () => {
    // todo: realize this function
    const updateData: IPanelUpdate = {
      id: panelId,
    };
    if (publishVersion) {
      updateData.publishVersion = publishVersion;
    }
    if (devVersion) {
      updateData.devVersion = devVersion;
    }
    console.log('@updateData', updateData);
    await props.action.panel.updateVersion(updateData);
  };

  // data
  const panel: IPanel | undefined = props.panel.panel;
  const setupData = async () => {
    await props.action.panel.getPanelDetail(panelId);
    console.log('@panel data', props.panel.panel);
  };

  // subcomponents
  // panel status display and setting part
  const $statusPart = (
    <Box
      align="stretch"
      justify="start"
      border={{color: 'active', side: 'top'}}
      margin={{top: 'medium'}}
      gap="small">
      <Box align="center" justify="between" direction="row">
        <Heading size="small" level="2">
          Status
        </Heading>
        <Button
          label="Save"
          size="small"
          secondary={false}
          primary
          onClick={updatePanelVersion}
        />
      </Box>
      <FormField label="Publish Version">
        <Select
          placeholder="place select publish version"
          options={
            panel?.versions.map((versionItem) => versionItem.version) || []
          }
          value={publishVersion}
          onChange={({value}) => {
            setPublishVersion(value);
          }}
        />
      </FormField>
      <FormField label="Dev Version">
        <Select
          placeholder="place create a version"
          options={
            panel?.versions.map((versionItem) => versionItem.version) || []
          }
          value={devVersion}
          onChange={({value}) => {
            setDevVersion(value);
          }}
        />
      </FormField>
    </Box>
  );
  // version part
  const $versionList = panel?.versions && panel.versions.length > 0 && (
    <List
      data={panel?.versions}
      action={(item: IPanelVersionItem, index: number) => {
        console.log('@data', item, index);
        return (
          <Box key={item.version}>
            <Button
              label="Detail"
              plain={true}
              onClick={() => setPanelVersionDetail(item)}
            />
          </Box>
        );
      }}
      primaryKey="version"
      secondaryKey="description"
    />
  );
  const $versionItemDetail = panelVersionDetail && (
    <Box
      className={styles.panelVersionItemDetailContainer}
      justify="center"
      align="center"
      pad="medium">
      <Box
        pad="small"
        className={styles.panelVersionItemContent}
        style={{borderColor: brandColor}}>
        <code>
          <pre>{JSON.stringify(panelVersionDetail, null, '  ')}</pre>
        </code>
      </Box>
      <Button
        label="Close"
        primary={true}
        margin={{top: 'medium'}}
        onClick={() => setPanelVersionDetail(null)}
      />
    </Box>
  );
  const $versionListEmptyTip = (panel?.versions || []).length === 0 && (
    <Text textAlign="center" color="text-xweak">
      No version exists
    </Text>
  );
  const $versionPart = (
    <Box
      align="stretch"
      justify="start"
      border={{color: 'active', side: 'top'}}
      margin={{top: 'medium'}}
      gap="small">
      <Box align="center" justify="between" direction="row">
        <Heading size="small" level="2">
          Versions
        </Heading>
        <Button
          label="Create"
          size="small"
          primary
          onClick={() => {
            history.push(
              RouterMap.panel.createVersion.generatePathByParams({
                panelId: panelId,
              }),
            );
          }}
        />
      </Box>
      {$versionList}
      {$versionListEmptyTip}
    </Box>
  );
  const $content = (
    <Box
      fill="vertical"
      overflow="auto"
      align="stretch"
      flex="grow"
      justify="start">
      <Box align="stretch" justify="start" gap="xsmall">
        <Heading size="small" level="2">
          Info
        </Heading>
        <Box>
          <Text>name: {panel?.name}</Text>
          <Text>description: {panel?.description}</Text>
          <Text>id: {panel?.id}</Text>
          <Text>product count: {panel?.productIds.length}</Text>
          <Text>owner: {panel?.ownerName}</Text>
          <Text>type: {panel?.type}</Text>
        </Box>
      </Box>
      {$statusPart}
      {$versionPart}
    </Box>
  );

  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row"
      gap="small">
      <SideBarComponent />
      <MainContainer>
        <div className={styles.container}>
          <Heading size="small" textAlign="start">
            Panel Detail
          </Heading>
          {$content}
        </div>
      </MainContainer>
      {$versionItemDetail}
    </Box>
  );
};

/**
 * connect page component
 */

const mapState = (state: IRootState) => {
  return {
    panel: state.panel,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    panel: dispatch.panel,
  },
});

const PageContainer = connect(mapState, mapDispatch)(PanelCreatePage);

export default PageContainer;
