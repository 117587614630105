import React from 'react';
import {
  Box,
  Main,
  Heading,
  Button,
  FormField,
  TextInput,
} from 'grommet';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {IRootState, IDispatch} from '../../../store';

// components
import SideBarComponent from '../../../components/views/sideBar';

interface RouterParamTypes {
  applicationId: string;
}

interface IProps {
  application: IRootState['application'];
  //@ts-ignore
  product: IRootState['product'];
  action: {
    application: IDispatch['application'];
    product: IDispatch['product'];
  };
}

const Page = (props: IProps) => {
  // get application id
  const {applicationId} = useParams<RouterParamTypes>();
  const [name, setName] = React.useState('');

  React.useEffect(() => {}, []);

  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row"
      gap="small">
      <SideBarComponent />
      <Main fill="vertical" flex="grow" overflow="auto" pad="medium">
        <Heading size="small" textAlign="start">
          Create Product
        </Heading>

        <Box
          align="stretch"
          justify="center"
          background={{color: 'background-front'}}>
          <FormField label="Name">
            <TextInput
              placeholder="enter product name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </FormField>
          <Button
            label="Create"
            primary
            alignSelf="start"
            onClick={() =>
              props.action.product.create({name, id: applicationId})
            }
          />
        </Box>
      </Main>
    </Box>
  );
};

const mapState = (state: IRootState) => {
  return {
    application: state.application,
    product: state.product,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    application: dispatch.application,
    product: dispatch.product,
  },
});

const PageContainer = connect(mapState, mapDispatch)(Page);

export default PageContainer;
