import React, { CSSProperties } from "react";
import Text from "../../atoms/text";
import Textarea, { TextareaProps } from "../../atoms/textarea";
import Layout from "../../atoms/layout";
import "./index.scss";

interface TextFieldProps extends TextareaProps {
  title?: string;
  textareaStyle?: CSSProperties;
  textareaFocusStyle?: CSSProperties;
  size?: "normal" | "big";
  className?: string;
}

const TextareaField = (props: TextFieldProps) => {
  const { size = "normal", className = "" } = props;
  const $label = (
    <Text
      type={size === "normal" ? "label" : "big-label"}
      className="form-field-label"
    >
      {props.title}
    </Text>
  );
  const $input = (
    <Layout>
      <Textarea
        className="form-field-input"
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        rows={3}
        style={{ ...styles.input, ...props.textareaStyle }}
        focusStyle={{ ...styles.inputFocus, ...props.textareaFocusStyle }}
        value={props.value}
      />
    </Layout>
  );

  const containerClassName = `form-field-container ${className}`;

  return (
    <Layout
      direction="column"
      className={containerClassName}
      style={props.style}
    >
      {$label}
      {$input}
    </Layout>
  );
};

const styles: { [key: string]: CSSProperties } = {
  input: {
    display: "flex",
    flexGrow: 1,
    padding: "6px",
    fontSize: "22px",
    lineHeight: "33px",
    borderWidth: "2px",
    borderColor: "#000000",
    backgroundColor: "#f2f2f2",
    color: "#000000",
    borderStyle: "solid",
    borderRadius: "0px",
  },
  inputFocus: {
    backgroundColor: "#ffffff",
  },
};

export default TextareaField;
