import React, {useMemo, useCallback} from 'react';
import {AreaClosed, Line, Bar} from '@visx/shape';
import appleStock, {AppleStock} from '@visx/mock-data/lib/mocks/appleStock';
import {curveMonotoneX} from '@visx/curve';
import {GridRows, GridColumns} from '@visx/grid';
import {scaleTime, scaleLinear} from '@visx/scale';
import {
  withTooltip,
  Tooltip,
  TooltipWithBounds,
  defaultStyles,
} from '@visx/tooltip';
import {WithTooltipProvidedProps} from '@visx/tooltip/lib/enhancers/withTooltip';
import {localPoint} from '@visx/event';
import {LinearGradient} from '@visx/gradient';
import {bisector} from 'd3-array';
import {timeFormat} from 'd3-time-format';
import {AxisBottom, AxisTop} from '@visx/axis';

type TooltipData = AppleStock;

const stock = appleStock.slice(800);
console.log('@stock', stock);
export const background = '#e7eff7';
export const background2 = '#fefeff';
export const accentColor = '#965ad6';
export const accentColorDark = '#b08ae6';
const tooltipStyles = {
  ...defaultStyles,
  background: accentColor,
  border: '1px solid white',
  color: 'white',
};

// util
const formatDate = timeFormat("%b %d, '%y");

// accessors
const getDate = (d: any) => new Date(d.key ? d.key[0] : undefined);
const getStockValue = (d: any) => d.value;
const bisectDate = bisector<any, Date>(
  (d) => new Date(d.key ? d.key[0] : undefined),
).left;

export type AreaProps = {
  width: number;
  height: number;
  data?: Array<any>;
  title?: string;
  margin?: {top: number; right: number; bottom: number; left: number};
};

export default withTooltip<AreaProps, TooltipData>(
  ({
    width,
    height,
    margin = {top: 0, right: 0, bottom: 0, left: 0},
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    data = [],
    title = '',
  }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
    // console.log('###data', data);
    if (width < 10) return null;
    if (data.length < 2) return null;

    // bounds
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // scales
    const dateScale = useMemo(() => {
      const startDate = new Date(data[0].key[0]);
      const endDate = new Date(data[data.length - 1].key[0]);
      return scaleTime({
        range: [margin.left, innerWidth + margin.left],
        domain: [startDate, endDate] as [Date, Date],
      });
    }, [innerWidth, margin.left, data]);

    const dataScaleForAxisTop = useMemo(
      () =>
        scaleLinear({
          range: [margin.left, innerWidth + margin.left],
          domain: [0, 0] as [number, number],
        }),
      [innerWidth, margin.left],
    );

    let maxValue = 0;
    for (let i = 0, len = data.length; i < len; i++) {
      const value = data[i].value;
      if (maxValue < value) {
        maxValue = value;
      }
    }
    const stockValueScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top - 30, margin.top],
          domain: [0, maxValue],
          nice: true,
        }),
      [margin.top, innerHeight, maxValue],
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (
        event:
          | React.TouchEvent<SVGRectElement>
          | React.MouseEvent<SVGRectElement>,
      ) => {
        const {x} = localPoint(event) || {x: 0};
        const x0 = dateScale.invert(x);
        const index = bisectDate(data, x0, 1);
        const d0 = data[index - 1];
        const d1 = data[index];
        let d = d0;
        if (d1 && getDate(d1)) {
          d =
            x0.valueOf() - getDate(d0).valueOf() >
            getDate(d1).valueOf() - x0.valueOf()
              ? d1
              : d0;
        }
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: stockValueScale(getStockValue(d)),
        });
      },
      [showTooltip, stockValueScale, dateScale, data],
    );

    return (
      <div>
        <svg width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="url(#area-background-gradient)"
            rx={14}
          />
          <LinearGradient
            id="area-background-gradient"
            from={background}
            to={background2}
          />
          <LinearGradient
            id="area-gradient"
            from={accentColor}
            to={accentColor}
            toOpacity={0.1}
          />
          <GridRows
            left={margin.left}
            scale={stockValueScale}
            width={innerWidth}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            scale={dateScale}
            height={innerHeight}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0.2}
            pointerEvents="none"
          />
          <AreaClosed<any>
            data={data}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => stockValueScale(getStockValue(d)) ?? 0}
            yScale={stockValueScale}
            strokeWidth={1}
            stroke="url(#area-gradient)"
            fill="url(#area-gradient)"
            curve={curveMonotoneX}
          />
          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          {tooltipData && (
            <g>
              <Line
                from={{x: tooltipLeft, y: margin.top}}
                to={{x: tooltipLeft, y: innerHeight + margin.top}}
                stroke={accentColorDark}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={accentColorDark}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
          <AxisBottom
            scale={dateScale}
            numTicks={10}
            orientation="bottom"
            top={height - 30}
            stroke={accentColorDark}
            tickStroke={accentColorDark}
          />
          <AxisTop
            scale={dataScaleForAxisTop}
            hideAxisLine={true}
            hideTicks={true}
            label={title}
            labelOffset={10}
            top={50}
            orientation="top"
            hideZero={true}
          />
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              key={Math.random()}
              top={tooltipTop - 12}
              left={tooltipLeft + 12}
              style={tooltipStyles}>
              {`Count: ${getStockValue(tooltipData)}`}
            </TooltipWithBounds>
            <Tooltip
              top={innerHeight + margin.top - 14}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: 'center',
                transform: 'translateX(-50%)',
              }}>
              {formatDate(getDate(tooltipData))}
            </Tooltip>
          </div>
        )}
      </div>
    );
  },
);
