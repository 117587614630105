import React from 'react';
import {
  Box,
  Text,
  Heading,
  Button,
  TextInput,
  Tab,
  Paragraph,
  Image,
} from 'grommet';
import {useParams} from 'react-router-dom';
import {useMountedState} from 'react-use';
import Connector, {IComponentProps} from '../../connector';

// types
import {IProduct, IProductUpdate} from '@/types/product';

// components
import Upload from '@/components/views/upload';

import styles from './tabs.module.scss';

interface RouterParamTypes {
  applicationId: string;
  productId: string;
}

function TabComponent(props: IComponentProps) {
  const isMounted = useMountedState();
  // get application id
  const {applicationId} = useParams<RouterParamTypes>();
  const [iconURL, setIconURL] = React.useState<string>('');

  // watch product properties change
  React.useEffect(
    () => {
      if (props.product.product) {
        setIconURL(props.product.product.icon);
      }
    },
    // @ts-ignore
    [props.product.product],
  );

  // @ts-ignore
  if (!props.product.product) {
    return null;
  }

  const product: IProduct | undefined = props.product.product as
    | IProduct
    | undefined;

  const multiLanguageSupportConfig = [
    {
      label: 'Chinese',
      key: 'zh',
    },
    {
      label: 'English',
      key: 'en',
    },
  ];
  const multiLanguageDefaultValue = multiLanguageSupportConfig.reduce(
    (total: {[key: string]: string}, currentValue) => {
      total[currentValue.key] = '';
      return total;
    },
    {},
  );
  const $multiLanguageInputs = product && (
    <Box
      align="stretch"
      justify="center"
      margin={{top: 'medium'}}
      border={{color: 'active', side: 'top'}}>
      {/* document config */}
      <Heading level={3} className={styles.groupTitle}>
        Multi-language
      </Heading>
      <Paragraph fill>
        This will display in application's deive list and device views.
      </Paragraph>
      {multiLanguageSupportConfig.map((item) => {
        return (
          <Box
            direction="row"
            gap="medium"
            margin={{bottom: 'small'}}
            key={item.key}>
            <Paragraph className={styles.multiLanguageInputLabel}>
              {item.label}
            </Paragraph>
            <TextInput
              placeholder="Product Name"
              value={product?.names[item.key] || ''}
              onChange={(event) => {
                const value = event.target.value;
                props.action.product.updateProductProperties({
                  names: {
                    ...multiLanguageDefaultValue,
                    ...product.names,
                    [item.key]: value,
                  },
                });
              }}
            />
          </Box>
        );
      })}
    </Box>
  );

  // help document
  const documentURLConfig: {[key: string]: string} = {
    en: '',
    zh: '',
    ...(product?.documentURL && typeof product?.documentURL === 'object'
      ? product?.documentURL
      : {}),
  };
  console.log('#documentURLConfig', documentURLConfig);
  const $helpDocumentItems = Object.keys(documentURLConfig).map((key) => {
    const url = documentURLConfig[key];
    return (
      <Box key={key}>
        <Paragraph fill>
          <b>{key}: </b>
        </Paragraph>
        <TextInput
          placeholder="https://"
          value={url}
          onChange={(event) => {
            const value = event.target.value;
            props.action.product.updateProductProperties({
              documentURL: {
                ...documentURLConfig,
                [key]: value,
              },
            });
          }}
        />
        {product?.documentURL && (
          <Box margin={{top: 'small'}}>
            <Text>
              Preview:{' '}
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </Text>
          </Box>
        )}
      </Box>
    );
  });

  const $helpDocument = (
    <Box
      align="stretch"
      justify="center"
      margin={{top: 'medium'}}
      border={{color: 'active', side: 'top'}}>
      {/* document config */}
      <Heading level={3} className={styles.groupTitle}>
        Product Document
      </Heading>
      <Paragraph fill>
        <b>URL: </b>
        The webpage url for product and help view display in application
      </Paragraph>
      {$helpDocumentItems}
    </Box>
  );

  return (
    <Tab title="Display Config">
      <Box className={styles.tabContentContainer}>
        <Box align="stretch" justify="center">
          {/**
           * support type
           * */}
          <Box align="stretch" justify="center">
            <Heading level={3} className={styles.groupTitle}>
              Visualization
            </Heading>
            <Paragraph fill>
              <b>Icon: </b>
              The product icon display in application
            </Paragraph>
            <Box direction="row" gap="medium">
              <Upload
                tipLabel="Drag or Click to select icon file"
                onCompleteUpload={(url: string) => {
                  console.log('on icon upload', url);
                  if (isMounted()) {
                    setIconURL(url);
                    props.action.product.updateProductProperties({
                      icon: url,
                    });
                  }
                }}
                storagePath={(randomId: string, fileExtension: string) => {
                  if (!product) {
                    return `product/icon/cache/${randomId}.${fileExtension}`;
                  }
                  return `product/icon/${product.id}/${randomId}.${fileExtension}`;
                }}
              />
              {iconURL && (
                <Box
                  className={styles.iconImageContainer}
                  border={{color: 'active'}}>
                  <Image
                    fit="cover"
                    src={
                      /^https?:\/\//gi.test(iconURL)
                        ? iconURL
                        : `https://${iconURL}`
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
          {$multiLanguageInputs}
          {$helpDocument}

          {/* save button */}
          <Box
            align="end"
            margin={{
              top: 'large',
            }}
            pad={{top: 'medium'}}
            border={{color: 'active', side: 'top'}}>
            <Button
              primary
              label="Save"
              disabled={!product}
              onClick={() => {
                if (!product) {
                  return;
                }
                const updateData: IProductUpdate = {
                  id: product!.id,
                  applicationId,
                  icon: product.icon,
                  names: product.names,
                  documentURL: product.documentURL,
                };
                props.action.product.update(updateData);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Tab>
  );
}

export default Connector(TabComponent);
