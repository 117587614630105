import React from 'react';
import {base} from 'grommet';
import {connect} from 'react-redux';
import {IRootState, IDispatch} from '../../../store';

import './index.scss';
const Spinner = require('react-spinkit');

interface IProps {
  account: boolean;
  application: boolean;
  product: boolean;
}

export const LoadingComponent = (props: IProps) => {
  if (props.account || props.application || props.product) {
    return (
      <div className="loading-mask-container">
        <Spinner
          name="cube-grid"
          color={base.global?.colors?.brand || '#7D4CDB'}
        />
      </div>
    );
  }
  return null;
};

// export default LoadingComponent;

/**
 * connect page component
 */

const mapState = (state: IRootState) => {
  return {
    account: state.account.loading,
    application: state.application.loading,
    //@ts-ignore
    product: state.product.loading,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({});

const LoadingContainer = connect(mapState, mapDispatch)(LoadingComponent);

export default LoadingContainer;
