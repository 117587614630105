import React from 'react';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import {Grommet} from 'grommet';
import {connect} from 'react-redux';
import {IRootState, IDispatch} from './store';
import history from './util/history';

// style config
import theme from './theme';
import './style.scss';

// i18n
import './i18n';

// contexts
import Viewport from './contexts/viewport';

// components
import Layout from './components/atoms/layout';
import Loading from './components/views/loading';

// pages
import RouterConfig from './router/config';
interface ApplicationProps {
  account: IRootState['account'];
}

interface PathConfig {
  onlyForMember?: boolean;
  onlyForGuest?: boolean;
}

function Application(props: ApplicationProps) {
  // path help function
  const path = (pathName: string) => (config: PathConfig = {}) => (
    $component: React.ReactNode,
    option: {key: string},
  ) => {
    const {onlyForMember, onlyForGuest} = config;

    let $content = $component;
    if (onlyForMember) {
      if (!props.account.isLoggedIn) {
        $content = <Redirect to="/login" />;
      }
    }

    if (onlyForGuest) {
      if (props.account.isLoggedIn) {
        $content = <Redirect to="/list" />;
      }
    }

    return (
      <Route path={pathName} key={option.key}>
        {' '}
        {$content}{' '}
      </Route>
    );
  };

  const $pageContentView = (
    <Switch>
      {RouterConfig.map((RouterConfigItem) => {
        const PageElement = RouterConfigItem.page;
        return path(RouterConfigItem.path)(
          RouterConfigItem.rule || {},
        )(<PageElement key={RouterConfigItem.path} />, {
          key: RouterConfigItem.path,
        });
      })}
      {/* diary */}
      {/*       
      {path('/list')({onlyForMember: true})(<ListPage />)}
      {path('/create')({onlyForMember: true})(<CreatePage />)}
      {path('/detail/:id')({})(<DetailPage />)}
      {path('/welcome')({})(<WelcomPage />)}
      */}
      {/* home page */}
      <Route path="/">
        <Redirect
          to={props.account.isLoggedIn ? '/application/list' : '/login'}
        />
      </Route>
    </Switch>
  );

  return (
    <Grommet full theme={theme}>
      <Viewport.Provider>
        <Router history={history}>
          <Layout direction="column" grow={true} style={styles.container}>
            {$pageContentView}
            <Loading />
          </Layout>
        </Router>
      </Viewport.Provider>
    </Grommet>
  );
}

const mapState = (state: IRootState) => {
  return {
    account: state.account,
  };
};
const mapDispatch = (dispatch: IDispatch) => ({});
const ApplicationContainer = connect(mapState, mapDispatch)(Application);
export default ApplicationContainer;

const styles = {
  container: {
    alignItems: 'stretch',
  },
};
