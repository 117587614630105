import React, { CSSProperties } from "react";
import './index.scss'

export interface LayoutProps {
  direction?: "row" | "column";
  children?: React.ReactNode;
  style?: CSSProperties;
  center?: boolean;
  alignItemsCenter?: boolean;
  disableSelection?: boolean;
  grow?: boolean;
  onPress?: () => void;
  className?: string;
}

const Layout = (props: LayoutProps) => {
  const {
    direction = "row",
    children,
    style = {},
    center,
    alignItemsCenter,
    disableSelection = false,
    grow = false,
    onPress,
    className = "",
    ...otherProps
  } = props;

  let containerClassName = "layout-basic";
  if (direction) {
    containerClassName += ` layout-${direction}`;
  }
  if (grow) {
    containerClassName += " layout-grow";
  }

  if (disableSelection) {
    containerClassName += " layout-no-select";
  }

  if (alignItemsCenter) {
    containerClassName += " layout-align-items-center";
  }
  if (center) {
    containerClassName += " layout-justify-content-center";
  }

  containerClassName += ` ${className}`;

  return (
    <div
      className={containerClassName}
      style={style}
      onClick={onPress}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default Layout;
