import React from 'react';
import {
  Box,
  Heading,
  Button,
  FormField,
  TextInput,
  TextArea,
  Text,
} from 'grommet';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {IRootState, IDispatch} from '../../store';
// import {RouterMap} from '../../router/config';

// components
import Main from '../../components/atoms/mainContainer';
import SideBarComponent from '../../components/views/sideBar';
import Upload from '../../components/views/upload';
// types
import {IPanelVersionItem} from '../../types/panel';

interface Props {
  panel: IRootState['panel'];
  action: {panel: IDispatch['panel']};
}

interface RouterParamTypes {
  panelId: string;
}

const PanelCreatePage = (props: Props) => {
  // path params
  const {panelId} = useParams<RouterParamTypes>();
  // state
  // page state
  const [step, setStep] = React.useState<number>(0);
  // form data
  const [versionName, setVersionName] = React.useState<string>('');
  const [versionDescription, setVersionDescription] = React.useState<string>(
    '',
  );
  const [androidResource, setAndroidResource] = React.useState<string>('');
  const [iOSResource, setIOSResource] = React.useState<string>('');
  // const history = useHistory();
  // effects
  React.useEffect(() => {}, []);

  // actions
  const doCreateAction = async () => {
    const createData: IPanelVersionItem = {
      panelId: panelId,
      version: versionName,
      description: versionDescription,
      resource: {
        android: androidResource,
        ios: iOSResource,
      },
      createTime: Date.now(),
    };
    console.log('@createData', createData);
    await props.action.panel.createVersion(createData);
  };

  // subcomponents
  const $basicInfo = (
    <Box align="stretch" justify="start" gap="small">
      <Heading level="3" size="small">
        Basic Info
      </Heading>
      <FormField label="Version">
        <TextInput
          placeholder="a.b.c"
          value={versionName}
          onChange={(event) => setVersionName(event.target.value)}
        />
      </FormField>
      <FormField label="Description">
        <TextArea
          placeholder="please enter version description"
          value={versionDescription}
          onChange={(event) => setVersionDescription(event.target.value)}
        />
      </FormField>
    </Box>
  );
  const $resources = (
    <Box align="stretch" justify="start" gap="small">
      <Text>Version: {versionName}</Text>
      <Text>Description: {versionDescription}</Text>
      <Heading level="3" size="small">
        Resources
      </Heading>
      <FormField label={`Android ${androidResource && '- Upload Success'}`}>
        <Upload
          onCompleteUpload={setAndroidResource}
          storagePath={(randomId: string) => {
            return `panel/${panelId}/${
              versionName || 'empty-version'
            }/${randomId}-android.zip`;
          }}
        />
      </FormField>
      <FormField label={`iOS ${iOSResource && '- Upload Success'}`}>
        <Upload
          onCompleteUpload={setIOSResource}
          storagePath={(randomId: string) => {
            return `panel/${panelId}/${
              versionName || 'empty-version'
            }/${randomId}-ios.zip`;
          }}
        />
      </FormField>
    </Box>
  );

  let $mainContent = null;
  let $footerButtons = null;
  switch (step) {
    case 0:
      $mainContent = $basicInfo;
      const canGoNextStep = versionName && versionDescription;
      $footerButtons = (
        <Box align="end" justify="start">
          <Button
            label="Next Step"
            primary
            onClick={() => setStep(1)}
            disabled={!canGoNextStep}
          />
        </Box>
      );
      break;
    case 1:
      $mainContent = $resources;
      const canExecuteCreateAction =
        versionName && versionDescription && androidResource && iOSResource;
      $footerButtons = (
        <Box align="start" justify="between" direction="row">
          <Button label="Previous Step" onClick={() => setStep(0)} />
          <Button
            label="Create Version"
            primary
            onClick={doCreateAction}
            disabled={!canExecuteCreateAction}
          />
        </Box>
      );
      break;
    default:
      break;
  }
  const $formContent = (
    <Box
      fill="vertical"
      overflow="auto"
      align="stretch"
      flex="grow"
      justify="start">
      {$mainContent}
      {$footerButtons}
    </Box>
  );

  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row"
      gap="small">
      <SideBarComponent />
      <Main fill="vertical" flex="grow" overflow="auto" pad="medium">
        <Heading size="small" textAlign="start">
          Create Panel
        </Heading>
        {$formContent}
      </Main>
    </Box>
  );
};

/**
 * connect page component
 */

const mapState = (state: IRootState) => {
  return {
    panel: state.panel,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    panel: dispatch.panel,
  },
});

const PageContainer = connect(mapState, mapDispatch)(PanelCreatePage);

export default PageContainer;
