import { Dispatch } from 'redux'
import { AxiosError } from 'axios'

// config
import Config from '../config'

// utils
import Network from '../util/network'
import APIMapping from '../util/APIMapping'
import Rule from '../util/rule'
// import Delay from '../util/delay';

/**
 * types
 */

interface DiaryItemForCreate {
    title: string;
    content: string;
}

interface DiaryItem {
    title: string;
    content: string;
    // meta
    id: string;
    creator: string;
    created_at: string;
    updated_at: string;
}

interface ErrorData {
    code: number | string;
    description: string;
}

export interface DiaryState {
    // data
    list: Array<DiaryItem>,
    detail: DiaryItem | null,
    // status
    loading: boolean;
    createSuccess: boolean,
    error: boolean;
    listHasBeenReachEnd: boolean;
    // others
    errorCode: number;
    errorDescription: string;
    currentPageIndexForList: number;
}

const initialState: DiaryState = {
    // data
    list: new Array<DiaryItem>(),
    detail: null,
    // status
    loading: false,
    createSuccess: false,
    error: false,
    listHasBeenReachEnd: false,
    // others
    errorCode: 0,
    errorDescription: '',
    currentPageIndexForList: 0,
}

const diary = {
    // initial state
    state: initialState,
    reducers: {
        clear(state: DiaryState, payload: undefined) {
            return initialState
        },
        updateLoadingStatus(state: DiaryState, payload: boolean) {
            return { ...state, loading: payload }
        },
        updateCreateResult(state: DiaryState, payload: { success: boolean, data: DiaryItem }) {
            return { ...state, createSuccess: payload.success, detail: payload.data }
        },
        clearCreateResultStatus(state: DiaryState, payload: undefined) {
            return { ...state, createSuccess: false }
        },
        updateList(state: DiaryState, payload: Array<DiaryItem>) {
            return { ...state, list: payload }
        },
        appendList(state: DiaryState, payload: Array<DiaryItem>) {
            return { ...state, list: [...state.list, ...payload] }
        },
        updateDetail(state: DiaryState, payload: DiaryItem) {
            return { ...state, detail: payload }
        },
        clearDetail(state: DiaryState, payload: undefined) {
            return { ...state, detail: null }
        },
        displayError(state: DiaryState, payload: ErrorData) {
            return { ...state, error: true, errorCode: payload.code, errorDescription: payload.description } as DiaryState
        },
        clearError(state: DiaryState, payload: undefined) {
            return { ...state, error: false, errorCode: 0, errorDescription: '' }
        },
        updateCurrentPageIndexForList(state: DiaryState, targetIndex: number) {
            return { ...state, currentPageIndexForList: targetIndex }
        },
        updateReachEndStatus(state: DiaryState, listHasBeenReachEnd: boolean) {
            return { ...state, listHasBeenReachEnd }
        },
    },
    effects: (dispatch: Dispatch) => ({
        async create(payload: DiaryItemForCreate) {
            dispatch.diary.clearError();
            // check format
            const { title, content } = payload
            if (title.length === 0) {
                dispatch.diary.displayError({
                    code: "empty-title",
                    description: "empty-title",
                });
                return;
            }
            if (content.length === 0) {
                dispatch.diary.displayError({
                    code: "empty-content",
                    description: "empty-content",
                });
                return;
            }
            // check format
            if (!Rule.diary.title.test(title)) {
                dispatch.diary.displayError({
                    code: "format-title",
                    description: "format-title",
                });
                return;
            }
            if (!Rule.diary.content.test(content)) {
                dispatch.diary.displayError({
                    code: "format-content",
                    description: "format-content",
                });
                return;
            }

            // request network
            dispatch.diary.updateLoadingStatus(true);

            try {
                const responseForCreate = await Network.instance.post(APIMapping.getPathForAPI(APIMapping.api.diary.create), payload);
                dispatch.diary.updateCreateResult({ success: !!responseForCreate.data.id, data: responseForCreate.data })
            } catch (e) {
                const error = e as AxiosError
                const statusCode = error.response?.status
                const description = error.response?.data?.error || 'Unknow error'
                dispatch.diary.displayError({ code: statusCode, description })
                if (statusCode === 401) {
                    await dispatch.account.logout()
                }
            } finally {
                dispatch.diary.updateLoadingStatus(false)
            }

        },
        async getListNextData(payload: undefined, rootState: any) {
            if (rootState.diary.loading as boolean) {
                return;
            }
            if (rootState.diary.listHasBeenReachEnd as boolean) {
                return;
            }
            if (rootState.diary.currentPageIndexForList as number > 0 && rootState.diary.list.length === 0) {
                return;
            }
            dispatch.diary.clearError();
            const loading: boolean = rootState.diary.loading
            if (loading) {
                return;
            }
            const nextPageIndex: number = rootState.diary.currentPageIndexForList + 1
            const requestConfig = {
                params: {
                    page: nextPageIndex,
                    count: Config.diary.pageCount
                }
            }
            dispatch.diary.updateLoadingStatus(true);
            try {
                const responseForGetList = await Network.instance.get(APIMapping.getPathForAPI(APIMapping.api.diary.list), requestConfig)
                const listData = (responseForGetList.data || []) as Array<DiaryItem>
                if (listData.length > 0) {
                    dispatch.diary.appendList(listData)
                    dispatch.diary.updateCurrentPageIndexForList(nextPageIndex)
                } else {
                    // reach end
                    dispatch.diary.updateReachEndStatus(true)
                }
            } catch (e) {
                const error = e as AxiosError
                const statusCode = error.response?.status
                const description = error.response?.data?.error || 'Unknow error'
                dispatch.diary.displayError({ code: statusCode, description })
            } finally {
                dispatch.diary.updateLoadingStatus(false);
            }
        },
        async getDetailById(payload: string) {
            dispatch.diary.clearError();
            dispatch.diary.clearDetail();

            const id = payload;
            // request network
            dispatch.diary.updateLoadingStatus(true);
            // await Delay(2);
            try {
                const responseForGetDetail = await Network.instance.get(APIMapping.getPathForAPI(APIMapping.api.diary.detail, { id }))
                const detailData = responseForGetDetail.data as DiaryItem
                dispatch.diary.updateDetail(detailData)
            } catch (e) {
                const error = e as AxiosError
                const statusCode = error.response?.status
                const description = error.response?.data?.error || 'Unknow error'
                dispatch.diary.displayError({ code: statusCode, description })
            } finally {
                dispatch.diary.updateLoadingStatus(false);
            }
        },
    }),
}

export default diary