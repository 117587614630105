import React from 'react';
import {Box, Heading, Button, List} from 'grommet';
import {MarginType} from 'grommet/utils';
import {useParams, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {IRootState, IDispatch} from '../../../store';
import {RouterMap} from '../../../router/config';

// components
import Main from '../../../components/atoms/mainContainer';
import NavBarComponent from '../components/navBar';
import SideBarComponent from '../../../components/views/sideBar';

interface RouterParamTypes {
  applicationId: string;
}

interface IProps {
  application: IRootState['application'];
  //@ts-ignore
  product: IRootState['product'];
  action: {
    application: IDispatch['application'];
    product: IDispatch['product'];
  };
}

const Page = (props: IProps) => {
  // get application id
  const {applicationId} = useParams<RouterParamTypes>();
  const history = useHistory();

  React.useEffect(() => {
    applicationId &&
      props.action.product.getListDataForApplication({applicationId});
  }, [applicationId, props.action.product]);

  // content list
  const listMargin: MarginType = {
    top: 'large',
    bottom: 'large',
  };
  const $list = props.product.list && props.product.list.length > 0 && (
    <List
      data={props.product.list}
      primaryKey="name"
      secondaryKey="id"
      onClickItem={(event: {item?: any; index?: number | undefined}) => {
        const {item} = event;
        // console.log(item, index);
        const redirectPath = RouterMap.application.product.detail.generatePathByParams(
          {
            applicationId: applicationId,
            productId: item.id,
          },
        );
        history.push(redirectPath);
      }}
      margin={listMargin}
    />
  );

  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row"
      gap="small">
      <SideBarComponent />
      <Main fill="vertical" flex="grow" overflow="auto" pad="medium">
        <Heading size="small" textAlign="start">
          Application / {props.application.application?.name}
        </Heading>
        <NavBarComponent applicationId={applicationId} />

        {/* content */}
        {$list}
        <Button
          label="Create new product"
          alignSelf="start"
          onClick={() => {
            history.push(
              RouterMap.application.product.create.generatePathByParams({
                applicationId,
              }),
            );
          }}
          margin={{top: 'medium'}}
        />
      </Main>
    </Box>
  );
};

const mapState = (state: IRootState) => {
  return {
    application: state.application,
    // @ts-ignore
    product: state.product,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    application: dispatch.application,
    product: dispatch.product,
  },
});

const PageContainer = connect(mapState, mapDispatch)(Page);

export default PageContainer;
