import React from 'react';
import RCUpload, {UploadProps} from 'rc-upload';
import Network from '../../../util/network';
import APIMapping from '../../../util/APIMapping';
import {Box, Button} from 'grommet';
import {v4 as generateUUID} from 'uuid';
import {useMountedState} from 'react-use';
// sdk
import COS from 'cos-js-sdk-v5';
// types
import {Json} from '../../../types/json';
// styles
import styles from './upload.module.scss';

enum UploadComponentStatus {
  setup = 'setup',
  setupFailed = 'setupFailed',
  ready = 'ready',
  uploading = 'uploading',
  uploadSuccess = 'uploadSuccess',
  uploadFailed = 'uploadFailed',
}

interface IUploadComponentProps extends UploadProps {
  tipLabel?: string;
  storagePath?: (randomId: string, fileExtension: string) => string;
  onCompleteUpload?: (url: string) => void;
}

const UploadComponent = (props: IUploadComponentProps) => {
  const isMounted = useMountedState();
  const [status, setStatus] = React.useState<UploadComponentStatus>(
    UploadComponentStatus.setup,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [credentials, setCredentials] = React.useState<any>({});
  const [cos, setCOS] = React.useState<COS | undefined>(undefined);
  const [uploadPercent, setUploadPercent] = React.useState<number>(0);

  React.useEffect(() => {
    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setup = async () => {
    try {
      const response = await Network.instance.get(
        APIMapping.getPathForAPI(APIMapping.api.resource.uploadAuthorization),
        {},
      );
      const {code, data, description} = response.data;
      if (code === 200 && isMounted()) {
        // console.log('@@@', response, data);
        setCredentials(data as Json);
        setStatus(UploadComponentStatus.ready);

        const _cos = new COS({
          getAuthorization: function (options, callback) {
            const callbackData = {
              TmpSecretId: data.credentials.tmpSecretId as string,
              TmpSecretKey: data.credentials.tmpSecretKey as string,
              XCosSecurityToken: data.credentials.sessionToken as string,
              StartTime: data.startTime as number,
              ExpiredTime: data.expiredTime as number,
              ScopeLimit: false,
            };
            console.log('@callbackData', callbackData);
            callback(callbackData);
          },
          ProgressInterval: 300,
        });
        setCOS(_cos);
      } else {
        console.log('Error for setup UploadComponent', code, description);
        if (isMounted()) {
          setStatus(UploadComponentStatus.setupFailed);
          setTimeout(setup, 100);
        }
      }
    } catch (e) {
      console.log('Error for setup UploadComponent', e);
      if (isMounted()) {
        setStatus(UploadComponentStatus.setupFailed);
        setTimeout(setup, 100);
      }
    }
  };

  let buttonLabelText = props.tipLabel || 'Drag or Click to select file';
  switch (status) {
    case UploadComponentStatus.ready:
      buttonLabelText = props.tipLabel || 'Drag or Click to select file';
      break;
    case UploadComponentStatus.setup:
      buttonLabelText = 'Loading';
      break;
    case UploadComponentStatus.setupFailed:
      buttonLabelText =
        'Something wrong for init upload component, please refresh';
      break;
    case UploadComponentStatus.uploadFailed:
      buttonLabelText = 'Something wrong for uploading, please refresh';
      break;
    case UploadComponentStatus.uploadSuccess:
      buttonLabelText = 'Upload success';
      break;
    case UploadComponentStatus.uploading:
      buttonLabelText = `Uploading...${uploadPercent * 100}%`;
      break;
  }

  return (
    <Box className={styles.container}>
      <RCUpload
        className={styles.upload}
        type="drag"
        beforeUpload={(file) => {
          return true;
        }}
        customRequest={({file}) => {
          setStatus(UploadComponentStatus.uploading);
          
          // 获取文件后缀
          const lastDot = file.name.lastIndexOf('.');
          const fileExtension = file.name.substring(lastDot + 1);

          const storageKey = props.storagePath
            ? props.storagePath(generateUUID(), fileExtension)
            : `cache/${generateUUID()}-${fileExtension}`;
          cos?.putObject(
            {
              Bucket: 'atomic-global-1258148781' /* 必须 */,
              Region: 'ap-hongkong' /* 存储桶所在地域，必须字段 */,
              Key: storageKey /* 必须 */,
              StorageClass: 'STANDARD',
              Body: file, // 上传文件对象
              onProgress: function (progressData) {
                console.log(JSON.stringify(progressData));
                setUploadPercent(progressData.percent);
              },
            },
            function (err, data) {
              console.log('Upload finished', err || data);
              if (!err) {
                // success
                const fileURL: string = data.Location;
                props.onCompleteUpload && props.onCompleteUpload(fileURL);
                setStatus(UploadComponentStatus.uploadSuccess);
              } else {
                setStatus(UploadComponentStatus.uploadFailed);
              }
            },
          );
        }}
        {...props}>
        <Box
          className={styles.visualBox}
          align="center"
          justify="center"
          background={{color: 'light-1'}}
          border={{color: 'active'}}
          pad="small">
          <Button label={buttonLabelText} plain />
        </Box>
      </RCUpload>
    </Box>
  );
};

export default UploadComponent;
