import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IRootState, IDispatch } from "../../store";
import "./resources/styles/index.scss";

// contexts
import Viewport from "../../contexts/viewport";

// components
import Page from "../../components/views/page";
import Text from "../../components/atoms/text";
import Button from "../../components/atoms/button";

// views
import Form from "../../components/views/form";
import Layout from "../../components/atoms/layout";

// resources
import HeaderImage from "./resources/images/girl-icon.svg";

interface RegisterPageProps {
  account: IRootState["account"];
  action: { account: IDispatch["account"] };
}

const RegisterPage = (props: RegisterPageProps) => {
  const { width } = React.useContext(Viewport.Context);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { t: translate } = useTranslation();

  React.useEffect(() => {
    props.action.account.clearError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t: (key: string) => string = (key: string) => {
    return translate(`account-${key}`);
  };

  // actions
  const doRegister = () => {
    // do log-in action
    props.action.account.register({
      name,
      email,
      password,
    });
  };

  // sub components
  const $headerImage = (
    <img src={HeaderImage} className="account-page-header-image" alt="avatar" />
  );
  const $name = (
    <Form.TextField
      title={t("name")}
      placeholder={t("name-placeholder")}
      onChange={setName}
    />
  );

  const $email = (
    <Form.TextField
      title={t("mail")}
      placeholder={t("mail-placeholder")}
      onChange={setEmail}
    />
  );
  const $password = (
    <Form.TextField
      title={t("password")}
      placeholder={t("password-placeholder")}
      onChange={setPassword}
      type="password"
    />
  );
  const $submitButton = (
    <Button
      title={t("register")}
      onPress={doRegister}
      className="account-page-submit-button"
      disabled={props.account.loading}
    />
  );
  const $error = props.account.error && props.account.errorCode && (
    <Layout
      center={true}
      alignItemsCenter={true}
      className="account-page-error-message"
    >
      <Text type="error">{t(`error-${props.account.errorCode}`)}</Text>
    </Layout>
  );
  const $linkToLogInPage = (
    <Layout center={true} alignItemsCenter={true}>
      <Link to="/login">
        <Text type="link-highlight">{t("have-account-go-login")}</Text>
      </Link>
    </Layout>
  );

  /**
   * container style
   * only limit max width when window width greater than 400px
   */
  let containerClassName = "account-page-container";
  switch (true) {
    case width < 562:
      containerClassName += " account-page-container-min-size";
      break;
    default:
      containerClassName +=
        " account-page-container-with-margin account-page-container-max-size";
      break;
  }

  return (
    <Page
      alignItemsCenter={true}
      direction="column"
      navigationBarShown={true}
      title={t("register")}
      className={containerClassName}
    >
      {$headerImage}
      {$error}
      {$name}
      {$email}
      {$password}
      {$submitButton}
      {$linkToLogInPage}
    </Page>
  );
};

/**
 * connect page component
 */

const mapState = (state: IRootState) => {
  return {
    account: state.account,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    account: dispatch.account,
  },
});

const RegisterPageContainer = connect(mapState, mapDispatch)(RegisterPage);

export default RegisterPageContainer;
