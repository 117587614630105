const Rule = {
    account: {
        email: /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}$/,
        password: /^[\w\-_\\.]{6,30}$/,
        name: /.{4,30}/,
    },
    diary: {
        title: /.{1,120}/,
        content: /.{4,}/,
    },
}

export default Rule;