import React, { CSSProperties } from "react";
import Layout, { LayoutProps } from "../../atoms/layout";
import NavigationBar, {
  NavigationBarHeight,
  NavigationBarProps,
} from "../navigationBar";
import "./index.scss";

interface PageProps extends LayoutProps, NavigationBarProps {
  title?: string;
  navigationBarShown?: boolean;
}

const Page = (props: PageProps) => {
  const {
    title = "",
    navigationBarShown = true,
    style = {},
    children,
    navigationLeftView: leftView,
    navigationRightView: rightView,
    className = "",
    ...otherProps
  } = props;

  // navigation bar
  let $navigationBar;
  if (navigationBarShown) {
    $navigationBar = (
      <NavigationBar
        navigationLeftView={leftView}
        navigationRightView={rightView}
        title={title}
      />
    );
  }

  // container style
  const containerClassName = `page-container ${className}`;
  let containerStyle: CSSProperties = { ...style };
  if (navigationBarShown) {
    containerStyle.marginTop = `${NavigationBarHeight}px`;
  }

  return (
    <Layout
      {...otherProps}
      className={containerClassName}
      style={containerStyle}
      grow={true}
    >
      {$navigationBar}
      {children}
    </Layout>
  );
};

export default Page;
