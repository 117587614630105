import {connect} from 'react-redux';
import {IRootState, IDispatch} from '../../../store';

const mapState = (state: IRootState) => {
  return {
    application: state.application,
    // @ts-ignore
    product: state.product,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    application: dispatch.application,
    product: dispatch.product,
  },
});

export interface IComponentProps {
  application: IRootState['application'];
  // @ts-ignore
  product: IRootState['product'];
  action: {
    application: IDispatch['application'];
    product: IDispatch['product'];
  };
}

export default connect(mapState, mapDispatch);
