import {connect} from 'react-redux';
import {IRootState, IDispatch} from '../../../../store';

const mapState = (state: IRootState) => {
  return {
    application: state.application,
    // @ts-ignore
    product: state.product,
    panel: state.panel,
  };
};

const mapDispatch = (dispatch: IDispatch) => ({
  action: {
    application: dispatch.application,
    product: dispatch.product,
    panel: dispatch.panel,
  },
});

export interface IComponentProps {
  application: IRootState['application'];
  //@ts-ignore
  product: IRootState['product'];
  //@ts-ignore
  panel: IRootState['panel'];
  action: {
    application: IDispatch['application'];
    product: IDispatch['product'];
    panel: IDispatch['panel'];
  };
}

export default connect(mapState, mapDispatch);
