import React from 'react';
import {Box, Heading, Tabs} from 'grommet';
import {useParams, Link} from 'react-router-dom';

// components
import SideBarComponent from '@/components/views/sideBar';
import Main from '@/components/atoms/mainContainer';
import TabBasicInfo from './components/tabs/basicInfo';
import TabDisplayConfig from './components/tabs/displayConfig';
import TabNetworkConfig from './components/tabs/networkConfig';
import TabPanelConfig from './components/tabs/panelConfig';
import TabPlatformConfig from './components/tabs/platformConfig';

// types
import {IProduct} from '@/types/product';
import {RouterMap} from '@/router/config';

// styles
import styles from './productDetail.module.scss';

// connector
import Connector, {IComponentProps} from './connector';

interface RouterParamTypes {
  applicationId: string;
  productId: string;
}

const Page = (props: IComponentProps) => {
  // get application id
  const {applicationId, productId} = useParams<RouterParamTypes>();
  // const history = useHistory();

  // console.log('applicationId, productId', applicationId, productId);

  React.useEffect(
    () => {
      setupData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * data functions
   */
  const setupData = async () => {
    await props.action.application.getApplicationDetail(applicationId);
    await props.action.product.getProductDetail(productId);
  };

  /**
   * actions
   */

  const product: IProduct | undefined = props.product.product as
    | IProduct
    | undefined;

  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row">
      <SideBarComponent />
      <Main fill="vertical" flex="grow" overflow="auto" pad="medium">
        <div className={styles.contentContainer}>
          <Link
            to={RouterMap.application.product.list.generatePathByParams({
              applicationId,
            })}>
            <Heading
              size="small"
              textAlign="start"
              level="4"
              className="text-gray-500">
              Application / {props.application.application?.name}
            </Heading>
          </Link>
          {/* content */}
          <Heading className="mt-2 mb-6 text-gray-800 hover:opacity-80 transform-gpu transition hover:scale-105 hover:translate-x-4">Product / {product?.name || '... ...'}</Heading>
          <Box
            fill="vertical"
            overflow="auto"
            align="stretch"
            flex="grow"
            justify="start">
            <Tabs alignControls="start" alignSelf="stretch" className="mt-4">
              <TabBasicInfo />
              <TabDisplayConfig />
              <TabNetworkConfig />
              <TabPanelConfig />
              <TabPlatformConfig />
            </Tabs>
          </Box>
        </div>
      </Main>
    </Box>
  );
};

export default Connector(Page);
