import React, { CSSProperties } from "react";
import "./index.scss"

export interface TextareaProps {
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  focusStyle?: CSSProperties;
  rows?: number;
  value?: string;
  className?: string;
}

const convertCSSValueToNumber: (value: any) => number = (value: any) =>
  Number(value.toString().replace(/[a-zA-z]/g, ""));

const Textarea = (props: TextareaProps) => {
  const [value, setValue] = React.useState(props.defaultValue || "");
  const [isFocus, setIsFocus] = React.useState(false);
  const $ref_Input = React.useRef(null);

  const rows = props.rows || 3;
  const targetStyle = {
    ...(props.style || {}),
    ...(isFocus ? props.focusStyle || {} : {}),
  };

  const onInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    /**
     * automatic resize for textarea input
     */
    const target: HTMLTextAreaElement = e.target as HTMLTextAreaElement;
    const lineHeight: number = convertCSSValueToNumber(
      targetStyle.lineHeight || 21
    );
    const deviationValue =
      convertCSSValueToNumber(targetStyle.borderWidth || "0") * 2;
    let textareaHeight =
      parseInt(getComputedStyle(target).height.slice(0, -2), 10) -
      deviationValue;
    if (target.scrollHeight > textareaHeight) {
      target.style.height = `${target.scrollHeight}px`;
    } else {
      const minHeight = (lineHeight + 1) * rows + deviationValue + 9;
      while (
        textareaHeight >= target.scrollHeight &&
        textareaHeight > (lineHeight + 1) * rows + deviationValue
      ) {
        textareaHeight -= lineHeight;
      }
      let targetHeight = textareaHeight + deviationValue;
      if (targetHeight < minHeight) {
        targetHeight = minHeight;
      }
      target.style.height = `${targetHeight}px`;
    }

    // update value
    const targetValue = e.currentTarget.value;
    setValue(targetValue);
    // run onChange callback
    props.onChange && props.onChange(targetValue);
  };

  return (
    <textarea
      placeholder={props.placeholder}
      value={props.value !== undefined ? props.value : value}
      ref={$ref_Input}
      onChange={onInputChange}
      className={`textarea ${props.className || ""}`}
      style={targetStyle}
      rows={rows}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    />
  );
};

export default Textarea;
