import React, { CSSProperties } from "react";
import "./index.scss";

export interface InputProps {
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  focusStyle?: CSSProperties;
  type?: "text" | "password";
  value?: string;
  className?: string;
}

const Input = (props: InputProps) => {
  const [value, setValue] = React.useState(props.defaultValue || "");
  const [isFocus, setIsFocus] = React.useState(false);
  const $ref_Input = React.useRef(null);

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const targetValue = event.currentTarget.value;
    setValue(targetValue);
    props.onChange && props.onChange(targetValue);
  };

  const targetStyle = {
    ...(props.style || {}),
    ...(isFocus ? props.focusStyle || {} : {}),
  };

  return (
    <input
      placeholder={props.placeholder}
      value={props.value !== undefined ? props.value : value}
      ref={$ref_Input}
      onChange={onInputChange}
      className={`input ${props.className || ""}`}
      style={targetStyle}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      type={props.type || "text"}
    />
  );
};


export default Input;
