import {init, RematchRootState} from '@rematch/core';
import {models} from './models';

const store = init({
  models,
});

export type IStore = typeof store;
export type IDispatch = typeof store.dispatch;
export type IRootState = RematchRootState<typeof models>;

export default store;
