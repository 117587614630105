import React from 'react';
import {
  Box,
  Text,
  Heading,
  Button,
  List,
  TextInput,
  RadioButton,
  Tab,
} from 'grommet';
import {useParams} from 'react-router-dom';
// import {useMountedState} from 'react-use';
import Connector, {IComponentProps} from '../../connector';

// types
import {IProduct, IProductUpdate} from '@/types/product';
import {IPanel} from '@/types/panel';

import styles from './tabs.module.scss';

interface RouterParamTypes {
  applicationId: string;
  productId: string;
}

function TabComponent(props: IComponentProps) {
  // const isMounted = useMountedState();
  // get application id
  const {applicationId} = useParams<RouterParamTypes>();
  const [displayPanelList, setDisplayPanelList] = React.useState(false);

  // @ts-ignore
  if (!props.product.product) {
    return null;
  }

  const product: IProduct | undefined = props.product.product as
    | IProduct
    | undefined;

  const getPanelList = async () => {
    await props.action.panel.getListData();
  };

  const togglePanelSelectList = async () => {
    const needDisplayPanelList = !displayPanelList;
    setDisplayPanelList(needDisplayPanelList);
    if (needDisplayPanelList) {
      await getPanelList();
    }
  };

  const $selectPanelList = displayPanelList && (
    <Box align="stretch" justify="center" gap="medium">
      <TextInput placeholder="Search panel by name" />
      <List
        data={props.panel.list}
        primaryKey="name"
        secondaryKey="description"
        action={(item: IPanel, index: number) => {
          console.log('@data', item, index);
          return (
            <Box>
              <RadioButton
                name=""
                checked={item.id === product?.panelId}
                onChange={() => {
                  const updateData: IProductUpdate = {
                    id: product!.id,
                    applicationId,
                    panelId: item.id,
                  };
                  props.action.product.update(updateData);
                }}
              />
            </Box>
          );
        }}
        onClickItem={(event: {
          item?: {} | undefined;
          index?: number | undefined;
        }) => {
          const {item} = event;
          const updateData: IProductUpdate = {
            id: product!.id,
            applicationId,
            panelId: (item as IProduct).id,
          };
          props.action.product.update(updateData);
        }}
      />
    </Box>
  );

  return (
    <Tab title="Panel">
      <Box className={styles.tabContentContainer}>
        <Box align="stretch" justify="center">
          <Heading level={3} className={styles.groupTitle}>
            Panel Bind Info
          </Heading>
          <Box
            align="center"
            justify="between"
            direction="row"
            margin={{bottom: 'small', top: 'medium'}}>
            <Text>Panel: {props.panel.panel?.name || 'No panel binded.'}</Text>
            <Button
              label="Change"
              type="button"
              plain={false}
              primary={false}
              size="small"
              margin={{left: 'small'}}
              onClick={togglePanelSelectList}
            />
          </Box>
        </Box>
        {$selectPanelList}
      </Box>
    </Tab>
  );
}

export default Connector(TabComponent);
