import React from 'react';
import {Box, Tab} from 'grommet';
import {useParams} from 'react-router-dom';
import Connector, {IComponentProps} from '../connector';
import {useMountedState} from 'react-use';

// contexts
import Viewport from '@/contexts/viewport';

// utils
import Network from '@/util/network';
import APIMapping from '@/util/APIMapping';

// components
import StatisticsLine from '../../components/statistics/line';
import StatisticsBar from '../../components/statistics/bar';

import styles from './tabs.module.scss';

interface RouterParamTypes {
  id: string;
}

const Page = (props: IComponentProps) => {
  // get application id
  const {id} = useParams<RouterParamTypes>();
  const [dataForNativeModule, setDataForNativeModule] = React.useState<
    Array<any>
  >([]);
  const {width} = React.useContext(Viewport.Context);
  const isMounted = useMountedState();

  React.useEffect(
    () => {
      id && props.action.application.getApplicationDetail(id);
      setupData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, props.action.application],
  );

  // actions
  const setupData = async () => {
    const {
      data: statisticsDataForNodeJSCallNativeModule,
      status,
    } = await Network.instance.get(
      APIMapping.getPathForAPI(APIMapping.api.statistics.usage, {
        queryKey: 'nativeModuleUsageByHours',
      }),
      {},
    );
    // console.log(
    //   '@statisticsDataForNodeJSCallNativeModule',
    //   statisticsDataForNodeJSCallNativeModule,
    //   status,
    // );
    if (
      status === 200 &&
      statisticsDataForNodeJSCallNativeModule.code === 200
    ) {
      if (isMounted()) {
        setDataForNativeModule(statisticsDataForNodeJSCallNativeModule.data[0]);
      }
    }
  };

  return (
    <Tab title="Statistics">
      <Box className={styles.tabContentContainer}>
        <StatisticsLine
          width={(width - 220) / 2}
          height={500}
          data={dataForNativeModule}
          title="Native Module Call Counts"
        />
        <StatisticsBar width={(width - 220) / 2} height={500} />
      </Box>
    </Tab>
  );
};

export default Connector(Page);
