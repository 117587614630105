import React from 'react';
import {Box, Heading, Text, Button, Tabs} from 'grommet';
import {useParams} from 'react-router-dom';
import Connector, {IComponentProps} from './connector';

// contexts
// import Viewport from '../../../contexts/viewport';

// utils
// import Network from '../../../util/network';
// import APIMapping from '../../../util/APIMapping';

// components
import Main from '../../../components/atoms/mainContainer';
// import NavBarComponent from '../components/navBar';
import SideBarComponent from '../../../components/views/sideBar';
import TabStatistics from './tabs/statistics';
import TabConfig from './tabs/config';
import TabProducts from './tabs/products';

import styles from './detail.module.scss';

interface RouterParamTypes {
  id: string;
}

const Page = (props: IComponentProps) => {
  // get application id
  const {id} = useParams<RouterParamTypes>();
  React.useEffect(() => {
    id && props.action.application.getApplicationDetail(id);
  }, [id, props.action.application]);

  return (
    <Box
      fill="vertical"
      overflow="auto"
      align="center"
      flex="grow"
      direction="row"
      gap="small">
      <SideBarComponent />
      <Main fill="vertical" flex="grow" overflow="auto" pad="medium">
        <Heading size="small" textAlign="start">
          Application / {props.application.application?.name}
        </Heading>
        <Box
          margin={{bottom: 'medium'}}
          direction="row"
          justify="start"
          align="center"
          gap="small">
          <Text>ID: {props.application.application?.id}</Text>
          <Button
            size="small"
            label="copy"
            plain={true}
            onClick={() => {
              props.application.application?.id &&
                navigator.clipboard.writeText(props.application.application.id);
              setTimeout(() => {
                alert(`Copy successed (${props.application.application.id})`);
              }, 200);
            }}></Button>
        </Box>

        {/* <NavBarComponent applicationId={id} /> */}
        <Box
          align="center"
          justify="start"
          direction="row"
          // gap="xlarge"
          margin={{top: 'medium', bottom: 'medium'}}
          className={styles.contentContainer}>
          <Tabs alignControls="start" alignSelf="stretch">
            <TabStatistics />
            <TabConfig applicationId={id} />
            <TabProducts applicationId={id} />
          </Tabs>
        </Box>
      </Main>
    </Box>
  );
};

export default Connector(Page);
