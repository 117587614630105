import React from 'react';
import {Box} from 'grommet';
import {Link, useLocation} from 'react-router-dom';

interface IProps {}

interface ISideBarConfigItem {
  title: string;
  path: string;
  pathRule: RegExp;
}

const SideBarConfig: Array<ISideBarConfigItem> = [
  {
    title: 'Application',
    path: '/application/list',
    pathRule: /^\/application\/.?/gi,
  },
  {
    title: 'Panel',
    path: '/panel/list',
    pathRule: /^\/panel\/.?/gi,
  },
  {
    title: 'Profile',
    path: '/profile',
    pathRule: /^\/profile\/.?/gi,
  },
  {
    title: 'Help',
    path: '/help',
    pathRule: /^\/help\/.?/gi,
  },
];

const SideBarComponent = (props: IProps) => {
  const location = useLocation();
  const [path, setPath] = React.useState<string>('/');
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  React.useEffect(() => {
    setPath(location.pathname);
    for (let i = 0, len = SideBarConfig.length; i < len; i++) {
      const item = SideBarConfig[i];
      const active = item.pathRule.test(location.pathname);
      if (active) {
        setActiveIndex(i);
      }
    }
  }, [location]);

  // menu items
  const menuConfig = SideBarConfig.map((item, index) => {
    const active = item.pathRule.test(path);
    return {
      ...item,
      active,
      key: `${item.path}_${index}`,
    };
  });
  const $menuItems = menuConfig.map((item, index) => {
    const textColor = index === activeIndex ? 'text-blue-500' : 'text-gray-500'
    return (
      <Link to={item.path} key={item.key} style={{textDecoration: 'none'}} className={`${textColor} transition transform p-2 pl-4 pr-4 ml-2 mr-2 mt-1 mb-1 scale-200 text-base hover:bg-blue-100 rounded-md`}>
        {item.title}
      </Link>
    );
  });

  return (
    <div
    className={`flex-col justify-start items-stretch self-stretch border-gray-200 border-r-2 pt-1 pb-1`}
      // align="stretch"
      // direction="column"
      // flex={false}
      // gap="large"
      // pad="medium"
      // border={{side: 'right', color: 'brand'}}
      >
      <Box align="stretch" justify="center" gap="xsmall">
        {$menuItems}
      </Box>
    </div>
  );
};

export default SideBarComponent;
