import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import CommonResources from './common'
import AccountResources from './account'
import DiaryResources from './diary'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            zh: {
                translation: {
                    ...CommonResources.zh.translation,
                    ...AccountResources.zh.translation,
                    ...DiaryResources.zh.translation,
                }
            }
        },
        lng: "zh",
        fallbackLng: "zh",

        interpolation: {
            escapeValue: false
        }
    });