const Storage = {
    save: (key: string, value: string) => {
        localStorage.setItem(key, value)
    },
    get: (key: string) => {
        return localStorage.getItem(key)
    },
    delete: (key: string) => {
        localStorage.removeItem(key)
    },
    clear: (key: string) => {
        localStorage.clear()
    }
}

export const StorageKey = {
    authToken: 'AuthToken',
    userId: 'UserId',
    userEmail: 'UserEmail',
    userName: 'UserName',
}

export default Storage