import {createModel, RematchDispatch} from '@rematch/core';
import {AxiosError} from 'axios';
import Network from '../util/network';
import APIMapping from '../util/APIMapping';
import {RootModel} from './index';
import history from '../util/history';

// router
import {RouterMap} from '../router/config';

/**
 * types
 */
import {IPanel, IPanelCreate, IPanelUpdate, IPanelVersionItemCreate} from '../types/panel';

interface ErrorData {
  code: number | string;
  description: string;
}

type TPanelList = Array<IPanel>;

export interface PanelState {
  list: TPanelList;
  panel?: IPanel; // current panel which is selected
  // status
  loading: boolean;
  error: boolean;
  // others
  errorCode: number;
  errorDescription: string;
}

const initialState: PanelState = {
  // basic information
  list: [],
  panel: undefined,
  // status
  loading: false,
  error: false,
  // others
  errorCode: 0,
  errorDescription: '',
};
// @ts-ignore
const panel = createModel<RootModel>()({
  // initial state
  state: initialState,
  reducers: {
    // update common status
    updateLoadingStatus(state: PanelState, payload: boolean) {
      return {...state, loading: payload};
    },
    displayError(state: PanelState, payload: ErrorData) {
      return {
        ...state,
        error: true,
        errorCode: payload.code,
        errorDescription: payload.description,
      } as PanelState;
    },
    clearError(state: PanelState, payload: undefined) {
      return {...state, error: false, errorCode: 0, errorDescription: ''};
    },
    // update list
    clearList(state: PanelState, payload: undefined) {
      return {...state, list: []};
    },
    appendList(state: PanelState, list: TPanelList) {
      return {...state, list: [...state.list, ...list]};
    },
    replaceList(state: PanelState, list: TPanelList) {
      return {...state, list};
    },
    // update
    updatePanel(state: PanelState, panel: IPanel) {
      return {...state, panel};
    },
    clearPanel(state: PanelState) {
      return {...state, panel: undefined};
    },
  },
  //@ts-ignore
  effects: (dispatch: RematchDispatch<RootModel>) => ({
    async create(panelCreateData: IPanelCreate) {
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.post(
          APIMapping.getPathForAPI(APIMapping.api.panel.create),
          panelCreateData,
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          await dispatch.panel.updatePanel(data as IPanel);
          // redirect page
          const redirectPanelDetailPath = RouterMap.panel.detail.generatePathByParams(
            {panelId: data.id},
          );
          history.push(redirectPanelDetailPath);
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async createVersion(panelVersionCreateData: IPanelVersionItemCreate) {
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.post(
          APIMapping.getPathForAPI(APIMapping.api.panel.createVersion),
          panelVersionCreateData,
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          await dispatch.panel.updatePanel(data as IPanel);
          // redirect page
          const redirectPanelDetailPath = RouterMap.panel.detail.generatePathByParams(
            {panelId: data.id},
          );
          history.push(redirectPanelDetailPath);
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async updateVersion(panelUpdateData: IPanelUpdate) {
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.put(
          APIMapping.getPathForAPI(APIMapping.api.panel.update),
          panelUpdateData,
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          await dispatch.panel.updatePanel(data as IPanel);
          // redirect page
          const redirectPanelDetailPath = RouterMap.panel.detail.generatePathByParams(
            {panelId: data.id},
          );
          history.push(redirectPanelDetailPath);
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    // @ts-ignore
    async getListData(payload: undefined) {
      // request data
      dispatch.account.updateLoadingStatus(true);
      try {
        const response = await Network.instance.get(
          APIMapping.getPathForAPI(APIMapping.api.panel.list),
        );
        const {code, data, description} = response.data;
        if (code === 200) {
          dispatch.panel.replaceList(data);
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
    async getPanelDetail(id: string) {
      dispatch.account.updateLoadingStatus(true);
      dispatch.panel.clearPanel();
      try {
        const response = await Network.instance.get(
          APIMapping.getPathForAPI(APIMapping.api.panel.item, {id}),
          {},
        );
        const {code, data, description} = response.data;
        const panelData: IPanel = {...data, ownerName: ''} as IPanel;
        if (code === 200) {
          // query owner data
          const {data: responseForUserInfo} = await Network.instance.get(
            APIMapping.getPathForAPI(APIMapping.api.account.info, {
              id: panelData.ownerId,
              applicationId: '_platform_',
            }),
            {},
          );
          if (responseForUserInfo.code === 200) {
            panelData.ownerName = responseForUserInfo.data.username;
            dispatch.panel.updatePanel(panelData as IPanel);
          } else {
            dispatch.account.displayError({
              code: responseForUserInfo.code,
              description: responseForUserInfo.description,
            });
          }
        } else {
          dispatch.account.displayError({code: code, description});
        }
      } catch (e) {
        // parse and update error
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        const description = error.response?.data?.error || 'Unknow error';
        dispatch.account.displayError({code: statusCode, description});
      } finally {
        dispatch.account.updateLoadingStatus(false);
      }
    },
  }),
});

export default panel;
